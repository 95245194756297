import { createSlice } from '@reduxjs/toolkit';
import i18n from '~src/internationalisation/i18n';
import { apiCallBegan } from '~store/middleware/api/api';
import translations from '~src/internationalisation/translation-map.json';
import { toast } from '@oliasoft-open-source/react-ui-library';

const companies = createSlice({
  name: 'companies',
  initialState: {
    isFetching: false,
    list: [],
  },
  reducers: {
    companiesRequested: (companies) => {
      companies.isFetching = true;
    },
    companiesReceived: (companies, action) => {
      companies.isFetching = false;
      companies.list = action.payload.companies;
    },
  },
});

export const { companiesRequested, companiesReceived } = companies.actions;
export default companies.reducer;

/**
 * Get companies list
 */
export const getCompanies = () =>
  apiCallBegan({
    url: `/api/companies`,
    method: 'GET',
    onStart: companiesRequested.type,
    onSuccess: (response) => ({
      type: companiesReceived.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToGetCompanies),
        },
      });
    },
  });
