import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  Heading,
  Page,
  Loader,
  Spinner,
  Spacer,
  Text,
  Empty,
  Tabs,
} from '@oliasoft-open-source/react-ui-library';
import {
  useSocket,
  WEBSOCKET_EVENTS,
} from '~src/common/socket-contex/socket-context';
import {
  getCompareList,
  showCompareModalUpdated,
  addSimulationStatus,
  getSimulation,
  showCompareEstimatesModalUpdated,
  compareSelected,
} from '~store/entities/compare-estimates/compare-estimates';
import { CompareList } from '~views/compare-estimates/compare.list';
import { DeleteEstimateModal } from '~views/compare-estimates/delete-estimate.modal';
import { CompareModal } from '~views/compare-estimates/compare.modal';
import translations from '~src/internationalisation/translation-map.json';
import { DeleteCompareModal } from '~views/compare-estimates/delete-compare.modal';
import { EditCompareModal } from '~views/compare-estimates/edit-compare.modal';
import { CompareEstimatesModal } from '~views/compare-estimates/compare-estimates.modal';
import { CompareEstimatesResultsTab } from '~views/compare-estimates/compare-estimates-results/compare-estimates-results-tab';
import { CompareEstimatesInputTab } from '~views/compare-estimates/compare-estimates-results/compare-estimates-input-tab';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { CompareEstimatesDrawer } from './drawer/compare-estimates.drawer';

const CompareEstimates = ({
  isFetching,
  showCompareModal,
  showCompareEstimatesModal,
  showCompareEstimatesModalUpdated,
  getCompareList,
  list,
  activeCompare,
  activeEstimates,
  visibleEstimates,
  showCompareModalUpdated,
  addSimulationStatus,
  getSimulation,
  filteredEstimates,
  estimates,
  compareDesigns,
  compareSelected,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const { socket } = useSocket();
  const [selectedId, setSelectedId] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteCompareModalVisible, setDeleteCompareModalVisible] =
    useState(false);
  const [updateCompareModalVisible, setUpdateCompareModalVisible] =
    useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [estimateSelected, setEstimateSelected] = useState(null);

  const onClickDelete = (id) => {
    setSelectedId(id);
    setDeleteModalVisible(true);
  };

  const onClickDeleteCompare = (id) => {
    setSelectedId(id);
    setDeleteCompareModalVisible(true);
  };

  const onClickEditCompare = (compare) => {
    setEditItem(compare);
    setUpdateCompareModalVisible(true);
  };

  useEffect(() => {
    getCompareList(company);
    socket.on(WEBSOCKET_EVENTS.notification_message, (m) =>
      addSimulationStatus(m.notification),
    );
    return () => {
      compareSelected(null);
    };
  }, []);

  useEffect(() => {
    Promise.all(
      visibleEstimates.map((estimate) => {
        if (
          estimate?.simulation?.status === 'Finished' &&
          !estimate?.simulation?.fetched
        ) {
          return getSimulation(estimate.projectId);
        }

        return null;
      }),
    );
  }, [visibleEstimates]);

  return (
    <>
      <Page left={0} padding={0}>
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <CompareList
            companyId={company}
            list={list}
            showCompareModalUpdated={showCompareModalUpdated}
            showCompareEstimatesModalUpdated={showCompareEstimatesModalUpdated}
            onClickDeleteCompare={onClickDeleteCompare}
            onClickEditCompare={onClickEditCompare}
            setEditItem={setEditItem}
            estimates={estimates}
            setEstimateSelected={setEstimateSelected}
            estimateSelected={estimateSelected}
          />
          {activeCompare ? (
            <>
              {activeTabIndex === 1 && (
                <CompareEstimatesDrawer
                  estimates={activeEstimates}
                  filteredEstimates={filteredEstimates}
                  estimateSelected={estimateSelected}
                />
              )}
              <Column scroll padding>
                <Heading top>
                  {activeCompare.name} <Spacer width={10} />
                  <Text faint>
                    {visibleEstimates.length}{' '}
                    {estimateSelected
                      ? t(translations.estimates)
                      : t(translations.design)}
                  </Text>
                </Heading>
                <Tabs
                  value={activeTabIndex}
                  onChange={(e) => setActiveTabIndex(e.target.value)}
                  margin={0}
                  options={[
                    {
                      value: 0,
                      label: t(translations.input),
                      testId: 'comparison-input-tab',
                    },
                    {
                      value: 1,
                      label: t(translations.result),
                      testId: 'comparison-result-tab',
                    },
                  ]}
                />
                {activeTabIndex === 0 && (
                  <CompareEstimatesInputTab
                    companyId={company}
                    compare={activeCompare}
                    visibleEstimates={visibleEstimates}
                    showCompareEstimatesModalUpdated={
                      showCompareEstimatesModalUpdated
                    }
                    showCompareModalUpdated={showCompareModalUpdated}
                    onClickDelete={onClickDelete}
                    filteredEstimates={filteredEstimates}
                    compareDesigns={compareDesigns}
                    activeEstimates={activeEstimates}
                  />
                )}
                {activeTabIndex === 1 && visibleEstimates.length !== 0 && (
                  <CompareEstimatesResultsTab
                    visibleEstimates={visibleEstimates}
                    filteredEstimates={filteredEstimates}
                    compareDesigns={compareDesigns}
                    estimateSelected={estimateSelected}
                  />
                )}
              </Column>
            </>
          ) : (
            <Empty
              height="100%"
              width="100%"
              text={t(translations.compare_noComparisonSelected)}
            />
          )}
        </Row>
      </Page>
      {deleteModalVisible && (
        <DeleteEstimateModal
          setModalVisible={setDeleteModalVisible}
          selectedId={selectedId}
        />
      )}
      {deleteCompareModalVisible && (
        <DeleteCompareModal
          setModalVisible={setDeleteCompareModalVisible}
          selectedId={selectedId}
        />
      )}
      {updateCompareModalVisible && (
        <EditCompareModal
          compare={editItem}
          setModalVisible={setUpdateCompareModalVisible}
        />
      )}
      {showCompareModal && (
        <CompareModal
          setModalVisible={showCompareModalUpdated}
          name={activeCompare?.name}
          compareId={activeCompare?.compareId}
        />
      )}
      {showCompareEstimatesModal && (
        <CompareEstimatesModal
          setModalVisible={showCompareEstimatesModalUpdated}
          activeCompare={activeCompare}
          filteredEstimates={filteredEstimates}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    isFetching,
    list,
    estimates,
    showCompareModal,
    showCompareEstimatesModal,
    estimatesList,
  } = entities.compareEstimates;
  const activeCompare = list.find((item) => item.active);
  const updatedCompare = activeCompare
    ? { ...activeCompare, isPublic: String(activeCompare?.isPublic) }
    : activeCompare;
  const activeEstimates = activeCompare
    ? estimates.filter(
        (estimate) => estimate.compareId === activeCompare.compareId,
      )
    : [];
  const visibleEstimates = activeEstimates.filter(
    (item) => item?.visible !== false,
  );

  const filteredEstimates = estimatesList.filter((el) =>
    visibleEstimates.some((f) => f.projectId === el.projectId),
  );

  const compareDesigns =
    activeCompare?.designs?.map(
      ({
        accessed,
        country: countryName,
        countryCode,
        datasetid: datasetId,
        name,
        sort1: fieldName,
        sort2: siteName,
        sort3: wellName,
        sort4: wellboreName,
        updated,
        user_name: userName,
        wellboreid: wellboreId,
      }) => ({
        accessed,
        countryCode,
        countryName,
        datasetId,
        name,
        fieldName,
        siteName,
        wellName,
        wellboreName,
        updated,
        userName,
        wellboreId,
      }),
    ) ?? [];

  return {
    isFetching,
    list,
    estimates,
    activeCompare: updatedCompare,
    activeEstimates,
    showCompareModal,
    showCompareEstimatesModal,
    visibleEstimates,
    filteredEstimates,
    compareDesigns,
  };
};

const mapDispatchToProps = {
  getCompareList,
  showCompareModalUpdated,
  addSimulationStatus,
  getSimulation,
  showCompareEstimatesModalUpdated,
  compareSelected,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CompareEstimates),
  { style: { position: 'absolute', top: '50px' } },
);

export { Container as CompareEstimates };
