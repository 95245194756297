import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import {
  createHierarchyItem,
  updateHierarchyItem,
} from '~store/entities/hierarchy/hierarchy';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { designsSchemaValidator } from '~schemas/ajv-validators';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { IDesign, IWellbore } from '~common/interfaces/hierarchy.interfaces.ts';

type DesignModalProps = PropsFromRedux & {
  companyId: string;
  activeWellbore: IWellbore | null;
  isAdding: boolean;
  editItem: IDesign | null;
  setEditItem: (data: IDesign | null) => void;
  itemModalVisibleUpdated: (data: {
    type: HierarchyLevelType;
    value: boolean;
  }) => void;
};

const DesignModal = ({
  companyId,
  itemModalVisibleUpdated,
  createHierarchyItem,
  updateHierarchyItem,
  activeWellbore,
  editItem,
  setEditItem,
  isAdding,
}: DesignModalProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      wellbore: activeWellbore?.name,
      wellboreid: activeWellbore?.wellboreid,
      name: editItem?.name || '',
      designid: editItem?.designid || null,
    },
    resolver: getResolver(designsSchemaValidator),
  });

  const onSubmit = handleSubmit((data) => {
    delete data.wellbore;
    if (editItem) {
      updateHierarchyItem(companyId, HierarchyLevelType.Design, data.designid, {
        ...editItem,
        ...data,
      });
    } else {
      createHierarchyItem(companyId, HierarchyLevelType.Design, {
        name: data.name,
        wellboreid: activeWellbore?.wellboreid,
      });
    }

    setEditItem(null);
  });
  const onClose = () => {
    setEditItem(null);
    itemModalVisibleUpdated({ type: HierarchyLevelType.Design, value: false });
  };

  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          heading: editItem
            ? t(translations.projects_editDesign)
            : t(translations.projects_addNewDesign),
          width: '600px',
          content: (
            <form>
              <Field
                label={t(translations.wellbore)}
                labelLeft
                labelWidth="80px"
              >
                <Input
                  name="wellbore"
                  control={control}
                  errors={errors}
                  disabled={true}
                />
              </Field>
              <Field label={t(translations.design)} labelLeft labelWidth="80px">
                <Input
                  name="name"
                  control={control}
                  errors={errors}
                  disabled={isAdding}
                />
              </Field>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { createHierarchyItem, updateHierarchyItem };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(DesignModal), { isModal: true });

export { Container as DesignModal };
