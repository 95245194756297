import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  Modal,
  Message,
  useKeyboardEvent,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import styles from './received-error-modal.module.less';

export const ReceivedErrorModal = ({ error, onCloseModal }) => {
  const { t } = useTranslation();
  const errorMessage = Array.isArray(error?.errorMessages) ? (
    <>
      {error.errorMessages.map((msg, index) => (
        <div key={index} style={{ marginBottom: '4px' }}>
          {msg}
        </div>
      ))}
    </>
  ) : (
    error?.errorMessages || error.message
  );

  const content = (
    <>
      <div className={styles.headerContainer}></div>
      {errorMessage && (
        <div className={styles.resultContainer}>
          <Message
            message={{
              visible: true,
              icon: true,
              width: '100%',
              content: errorMessage,
              type: 'Error',
            }}
          />
        </div>
      )}
    </>
  );

  const onClose = () => {
    onCloseModal();
  };

  useKeyboardEvent('Escape', () => onClose());
  return (
    <Modal visible={error} centered>
      <Dialog
        dialog={{
          scroll: true,
          heading: t(translations.pasteWithReviewRoundBrackets),
          content,
          footer: (
            <>
              <Button label={t(translations.close)} onClick={onClose} />
            </>
          ),
          onClose,
          width: '75%',
        }}
      />
    </Modal>
  );
};
