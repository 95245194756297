import { to } from '@oliasoft-open-source/units';
import { getDatumEPSG, transformLocationObject } from '@oliasoft/gis-core';
import { isLocationObjectValid } from '~common/gis/validate-location';

export function calculateDistanceBetweenCoordinates(location1, location2) {
  if (!isLocationObjectValid(location1) || !isLocationObjectValid(location2))
    return 0;

  const { x: lon1, y: lat1 } = transformLocationObject(
    location1,
    getDatumEPSG(location1?.epsgCode),
  );
  const { x: lon2, y: lat2 } = transformLocationObject(
    location2,
    getDatumEPSG(location2?.epsgCode),
  );

  const R = 6371; // Radius of the earth in km
  const latitudeRad = to(lat2 - lat1, 'deg', 'rad');
  const longitudeRad = to(lon2 - lon1, 'deg', 'rad');

  const a =
    Math.sin(latitudeRad / 2) * Math.sin(latitudeRad / 2) +
    Math.cos(to(lat1, 'deg', 'rad')) *
      Math.cos(to(lat2, 'deg', 'rad')) *
      Math.sin(longitudeRad / 2) *
      Math.sin(longitudeRad / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c * 1000; // Distance in m
  return distance;
}
