import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from '@oliasoft-open-source/react-ui-library';
import {
  getItems,
  itemSelected,
  listCleaned,
} from '~store/entities/hierarchy/hierarchy';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  ICountry,
  ISelectedProject,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { useListSorting } from '~common/sorting/use-list-sorting.tsx';
import { FetchingList } from '~common/lists/fetching-list.tsx';

type CountriesListProps = PropsFromRedux & {
  companyId: string;
  countries: ICountry[];
  isCountryFetching: boolean;
  createItem: () => void;
  onClickDelete: (id: string, type: HierarchyLevelType) => void;
  editItem: (item: ICountry, type: HierarchyLevelType) => void;
  selectedProject: ISelectedProject;
};

const CountriesList = ({
  companyId,
  getItems,
  countries,
  onClickDelete,
  itemSelected,
  isCountryFetching,
  createItem,
  editItem,
  listCleaned,
  selectedProject,
}: CountriesListProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      await getItems(companyId, HierarchyLevelType.Country);
      itemSelected({
        type: HierarchyLevelType.Country,
        id: selectedProject.countryId,
      });
    };
    fetchData();
    return () => {
      listCleaned(HierarchyLevelType.Country);
    };
  }, [companyId]);
  const { items, sortAction } = useListSorting(countries);

  if (isCountryFetching) {
    return (
      <FetchingList testId="country-list" name={t(translations.country)} />
    );
  }

  return (
    <List
      testId="country-list"
      list={{
        name: t(translations.country),
        actions: [
          sortAction,
          {
            label: 'Add',
            icon: 'add',
            // @ts-ignore TODO: add testID in UI-library
            testId: 'add-country-button',
            disabled: isCountryFetching,
            onClick: () => createItem(),
          },
        ],
        items: items.map((country) => {
          const { childrenCount } = country.metadata;

          return {
            id: country.countryid,
            name: country.name,
            metadata: `${childrenCount} ${
              +childrenCount === 1
                ? t(translations.field)
                : t(translations.fields)
            }`,
            active: country.active,
            onClick: () => {
              itemSelected({
                id: country.countryid,
                type: HierarchyLevelType.Country,
              });
              localStorage.removeItem('selectedProject');
            },
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => editItem(country, HierarchyLevelType.Country),
              },
              {
                label: 'Delete',
                icon: 'delete',
                testId: `delete-country-${country.name.replace(/ /g, '-')}`,
                onClick: () =>
                  onClickDelete(country.countryid, HierarchyLevelType.Country),
              },
            ],
          };
        }),
      }}
      stickyHeader
      expanding
      scrollDetails={{
        scrollable: true,
        hideScrollbar: true,
      }}
    />
  );
};

const mapDispatchToProps = { itemSelected, getItems, listCleaned };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CountriesList));

export { Container as CountriesList };
