import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LineChart } from '@oliasoft-open-source/charts-library';
import { displayNumber } from '@oliasoft-open-source/units';
import translations from '~src/internationalisation/translation-map.json';
import { generateScatterChartViewData } from '~views/simulations/simulations';
import { selectSimulationsResult } from '~store/entities/simulations/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { generateActualChartLine } from './utils/generate-actual-chart-line';

const TimeTrackerChart = ({ operationList, simulationsResult }) => {
  const { t } = useTranslation();

  const operationVsDepth = simulationsResult?.operation_vs_depth;
  const chartViewData = generateScatterChartViewData(operationVsDepth, 0);
  const data = {
    datasets: [
      ...chartViewData,
      generateActualChartLine(operationList, t, translations),
    ],
  };
  const chartOptions = {
    options: {
      chartStyling: {
        height: '100%',
      },
      additionalAxesOptions: {
        reverse: true,
      },
      axes: {
        x: [
          {
            label: t(`${t(translations.time)} [${t(translations.days)}]`),
          },
        ],
        y: [
          {
            label: t(translations.depth),
          },
        ],
      },

      scales: {
        x: {
          ticks: {
            callback: (val) => {
              return displayNumber(val, { scientific: false });
            },
          },
        },
      },
    },
    data,
  };

  return (
    <div style={{ height: 'calc(100% - 92px)' }}>
      <LineChart chart={chartOptions} />
    </div>
  );
};

const mapStateToProps = ({ entities }) => {
  const simulationsResult = selectSimulationsResult(entities);
  return {
    simulationsResult,
  };
};

export default withErrorBoundary(connect(mapStateToProps)(TimeTrackerChart));
