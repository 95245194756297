import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OptionDropdown, Icon } from '@oliasoft-open-source/react-ui-library';
import { disabledFieldsUpdated } from '~store/entities/time-tracker/time-tracker';
import { itemFields } from '~src/enums/time-tracker';
import translations from '~src/internationalisation/translation-map.json';
import { selectTimeTrackerLabelCofig } from '~store/entities/company-settings/selectors';

const EditColumns = ({ disabledFieldsUpdated, labelConfig }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([
    { label: t(translations.timeTracker_timeHours), type: 'Heading' },
    {
      label: labelConfig?.[itemFields.AFE] || t(translations.timeTracker_afe),
      value: itemFields.AFE,
      selected: true,
    },
    {
      label:
        labelConfig?.[itemFields.ESTIMATE] ||
        t(translations.timeTracker_estimate),
      value: itemFields.ESTIMATE,
      selected: true,
    },
    {
      label: itemFields.P90,
      value: itemFields.P90,
      selected: false,
    },
    {
      label: itemFields.NPT.toUpperCase(),
      value: itemFields.NPT,
      selected: false,
    },
    {
      label: itemFields.WOW.toUpperCase(),
      value: itemFields.WOW,
      selected: false,
    },
    {
      label: t(translations.timeTracker_actual),
      value: itemFields.ACTUAL,
      selected: true,
    },
    {
      label: t(translations.timeTracker_variance),
      value: itemFields.VARIANCE,
      selected: true,
    },
    { type: 'Divider' },
    { label: t(translations.timeTracker_depthM), type: 'Heading' },
    {
      label: t(translations.timeTracker_planned),
      value: itemFields.PLANNED_DEPTH,
      selected: true,
    },
    {
      label: t(translations.timeTracker_actual),
      value: itemFields.ACTUAL_DEPTH,
      selected: true,
    },
    { type: 'Divider' },
    {
      label: t(translations.timeTracker_startTime),
      value: itemFields.START,
      selected: true,
    },
    {
      label: t(translations.timeTracker_finishTime),
      value: itemFields.FINISH,
      selected: false,
    },
    { type: 'Divider' },
    {
      label: 'Section',
      value: itemFields.SECTION,
      selected: false,
    },
    {
      label: 'Operation',
      value: itemFields.OPERATION,
      selected: false,
    },
    {
      label: 'Activity',
      value: itemFields.ACTIVITY,
      selected: false,
    },
  ]);

  const icon = <Icon icon="settings" size="16px" />;
  return (
    <OptionDropdown
      onChange={(e) => {
        setOptions(e.target.value);
        const disabledFields = e.target.value
          .filter((item) => item.selected === false)
          .map((item) => item.value);
        disabledFieldsUpdated(disabledFields);
      }}
      options={options}
      label={icon}
      showHeader={false}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding } = entities.timeTracker;
  const labelConfig = selectTimeTrackerLabelCofig(entities);
  return { isAdding, labelConfig };
};

const mapDispatchToProps = { disabledFieldsUpdated };
const Container = connect(mapStateToProps, mapDispatchToProps)(EditColumns);

export { Container as EditColumns };
