import { ISelectedProject } from '~src/common/interfaces/hierarchy.interfaces';

export const updateProjectHierarchy = (
  key: keyof ISelectedProject,
  newValue: string,
  selectedProject: ISelectedProject,
) => {
  const hierarchy = [
    'countryId',
    'fieldId',
    'siteId',
    'wellId',
    'wellboreId',
    'designId',
    'estimateId',
  ];

  const updatedProject: ISelectedProject = { ...selectedProject };

  let keyFound = false;
  for (const hierarchyKey of hierarchy) {
    if (hierarchyKey === key) {
      updatedProject[hierarchyKey] = newValue;
      keyFound = true;
    } else if (keyFound) {
      updatedProject[hierarchyKey as keyof ISelectedProject] = '';
    }
  }

  localStorage.setItem('selectedProject', JSON.stringify(updatedProject));
};
