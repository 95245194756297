import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from '@oliasoft-open-source/react-ui-library';
import {
  getItems,
  itemSelected,
  listCleaned,
} from '~store/entities/hierarchy/hierarchy';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  IWell,
  IWellbore,
  ISelectedProject,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { useListSorting } from '~common/sorting/use-list-sorting.tsx';
import { FetchingList } from '~common/lists/fetching-list.tsx';
import { isEmpty } from 'lodash';
import { updateProjectHierarchy } from '../hierarchy-utils/local-storage-util';

type WellboresListProps = PropsFromRedux & {
  companyId: string;
  wellbores: IWellbore[];
  activeWell: IWell | null;
  isWellboreFetching: boolean;
  addButtonDisabled: boolean;
  createItem: () => void;
  onClickDelete: (id: string, type: HierarchyLevelType) => void;
  editItem: (item: IWellbore, type: HierarchyLevelType) => void;
  selectedProject: ISelectedProject;
};

const WellboresList = ({
  companyId,
  wellbores,
  onClickDelete,
  activeWell,
  getItems,
  itemSelected,
  listCleaned,
  addButtonDisabled,
  isWellboreFetching,
  createItem,
  editItem,
  selectedProject,
}: WellboresListProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (activeWell?.wellid) {
      const fetchData = async () => {
        await getItems(
          companyId,
          HierarchyLevelType.Wellbore,
          activeWell.wellid,
        );
        if (!isEmpty(selectedProject.wellboreId)) {
          itemSelected({
            id: selectedProject.wellboreId,
            type: HierarchyLevelType.Wellbore,
          });
        }
      };
      fetchData();
    }

    return () => {
      listCleaned(HierarchyLevelType.Wellbore);
    };
  }, [companyId, activeWell]);
  const { items, sortAction } = useListSorting(wellbores);

  if (isWellboreFetching) {
    return (
      <FetchingList testId="wellbore-list" name={t(translations.wellbore)} />
    );
  }

  return (
    <List
      testId="wellbore-list"
      list={{
        name: t(translations.wellbore),
        actions: [
          sortAction,
          {
            label: 'Add',
            icon: 'add',
            onClick: () => createItem(),
            disabled: addButtonDisabled || isWellboreFetching,
          },
        ],
        items: items.map((wellbore) => {
          const { childrenCount } = wellbore.metadata;

          return {
            id: wellbore.wellboreid,
            name: wellbore.name,
            metadata: `${childrenCount} ${
              +childrenCount === 1
                ? t(translations.design)
                : t(translations.designs)
            }`,
            active: wellbore.active,
            onClick: () => {
              itemSelected({
                id: wellbore.wellboreid,
                type: HierarchyLevelType.Wellbore,
              });
              updateProjectHierarchy(
                'wellboreId',
                wellbore.wellboreid,
                selectedProject,
              );
            },
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => editItem(wellbore, HierarchyLevelType.Wellbore),
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(
                    wellbore.wellboreid,
                    HierarchyLevelType.Wellbore,
                  ),
              },
            ],
          };
        }),
      }}
      stickyHeader
      expanding
      scrollDetails={{
        scrollable: true,
        hideScrollbar: true,
      }}
    />
  );
};

const mapDispatchToProps = { getItems, itemSelected, listCleaned };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(WellboresList));

export { Container as WellboresList };
