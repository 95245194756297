import {
  displayNumber,
  round,
  roundToFixed,
} from '@oliasoft-open-source/units';
import { HOURS_PER_DAY } from '~src/enums/general';

export const prettifyValue = (data: number, isTime: boolean | null = null) => {
  if (data) {
    return isTime
      ? displayNumber(roundToFixed(data / HOURS_PER_DAY, 1))
      : displayNumber(round(data, 0), { scientific: false });
  } else return null;
};
