import { Button, Dialog, Modal } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { addRigOperationTemplate, updateRigOperationTemplateItem } from '~store/entities/company-settings/company-settings';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { rigOperationTemplateSchemaValidator } from '~schemas/ajv-validators';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { IRigOperationTemplate } from '~src/common/interfaces/rig-operations-template.interfaces';


interface RigOperationRenameModalProps {
  visible: boolean;
  rename: boolean;
}
interface RigOperationTemplateRenameModalProps extends PropsFromRedux {
  activeRigOperationTemplate: IRigOperationTemplate | undefined;
  setRigOperationRenameModalVisible: (props: RigOperationRenameModalProps) => void,
  rename: boolean;
}

const RigOperationTemplateRenameModal = ({
  setRigOperationRenameModalVisible,
  activeRigOperationTemplate ,
  updateRigOperationTemplateItem,
  addRigOperationTemplate,
  rename,
}: RigOperationTemplateRenameModalProps) => {

  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      rigOperationTemplateId: activeRigOperationTemplate?.rigOperationTemplateId || '',
      name: activeRigOperationTemplate?.name || '',
      activities: activeRigOperationTemplate?.activities || [],
    },
    resolver: getResolver(rigOperationTemplateSchemaValidator),
    mode: 'onChange',
  });
  const onClose = () => setRigOperationRenameModalVisible({visible:false, rename:false});
  const onSubmit = handleSubmit((data) => {
    if (rename) { updateRigOperationTemplateItem({...data})}
    else {addRigOperationTemplate({...data})};
    setRigOperationRenameModalVisible({visible:false, rename:false});
  });

  return (
    <Modal centered visible>
      <Dialog
        dialog={{
          heading: rename ?  t(translations.renameTemplate): t(translations.nameTemplate),
          content: (
            <form>
              <Input
                name="name"
                width={300}
                control={control}
                errors={errors}
              />
            </form>
          ),
          footer: (
            <>
              <Button
                label={rename ? t(translations.rename): t(translations.add)}
                colored
                onClick={onSubmit}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};


const mapDispatchToProps = { addRigOperationTemplate, updateRigOperationTemplateItem };


const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(RigOperationTemplateRenameModal),
  { isModal: true },
);

export { Container as RigOperationTemplateRenameModal };
