import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { pageStatuses } from '~src/enums/projects';

const slice = createSlice({
  name: 'projects',
  initialState: {
    overview: {},
  },
  reducers: {
    overviewRequested: (projects) => {
      projects.overview = {};
    },
    overviewReceived: (projects, action) => {
      projects.overview = action.payload;
    },
    projectNameUpdated: (projects, action) => {
      projects.overview.name = action.payload;
    },
    updatePageStatusRequested: (projects, actions) => {
      const { pages } = projects.overview;

      // Temporary logic for beta / external testing.
      switch (pages[actions.payload]) {
        case pageStatuses.ACTIVE:
          projects.overview.pages[actions.payload] = pageStatuses.FINISHED;
          break;
        case pageStatuses.FINISHED:
          projects.overview.pages[actions.payload] = pageStatuses.ACTIVE;
          break;
        case pageStatuses.DISABLED:
          projects.overview.pages[actions.payload] = pageStatuses.FINISHED;
          break;
        default:
      }
    },
  },
});

export const {
  overviewRequested,
  overviewReceived,
  projectNameUpdated,
  updatePageStatusRequested,
} = slice.actions;
export default slice.reducer;

/**
 * Get overview stats
 */
export const getOverviewStats = (projectId) =>
  apiCallBegan({
    url: `/api/projects/overview/${projectId}`,
    method: 'GET',
    onStart: overviewRequested.type,
    onSuccess: overviewReceived.type,
  });

/**
 *  Export project to json file
 *
 * @param id
 * @param name
 */
export const exportProject = (id, name) =>
  apiCallBegan({
    url: `/api/projects/export/${id}`,
    method: 'GET',
    onSuccess: (response) => {
      const dataString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(response),
      )}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataString);
      downloadAnchorNode.setAttribute('download', `${name}.json`);
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  });

/**
 * Updates the status of a page for a given project.
 *
 * @param {number} projectId - The ID of the project.
 * @param {string} page - The page to be updated.
 */
export const updatePageStatus = (projectId, page) =>
  apiCallBegan({
    url: `/api/projects/page/${projectId}`,
    method: 'POST',
    data: { page },
    onStart: () => ({
      type: updatePageStatusRequested.type,
      payload: page,
    }),
  });

/**
 * Group estimates into groups of planned or actual
 *
 * @param estimates
 */
export const groupedEstimates = (estimates) => {
  const groups = {};
  estimates.forEach((estimate) => {
    const { category } = estimate;
    groups[category] = groups[category] || [];
    groups[category].push(estimate);
  });
  return groups;
};
