import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { connect, ConnectedProps } from 'react-redux';
import {
  Heading,
  Row,
  Column,
  Spacer,
  Divider,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { getAllRigOperationTemplates } from '~src/store/entities/company-settings/company-settings';
import{IRigOperationTemplate} from '~src/common/interfaces/rig-operations-template.interfaces';
import type { TRootState } from '~src/store/store-types';
import { RigOperationTemplatesList } from './rig-operation-template-list';
import { RigOperationTemplateContent } from './rig-operation-template-content';
import { RigOperationTemplateRenameModal } from './rig-operation-template-rename.modal';

const RigOperationTemplate  = ({ isFetching, rigOperationTemplates, activeRigOperationTemplate,getAllRigOperationTemplates}: PropsFromRedux) => {
  const { t } = useTranslation();
  const [rigOperationRenameModalVisible, setRigOperationRenameModalVisible] =
  useState({visible:false, rename:false});
  useEffect(() => {
    getAllRigOperationTemplates();
  }, []);
  return (
    <>  <Column scroll={false} spacing={0}>
    {isFetching && (
      <Loader text={t(translations.fetching)} theme="light" cover>
        <Spinner dark />
      </Loader>
    )}
    <Spacer />
    <Row spacing={0}>
      <Spacer width="var(--padding-sm)" />
      <Heading top marginBottom={16}>
        {t(translations.rigOperationTemplates)}
      </Heading>
    </Row>
    <Divider margin={0} />
    <Row spacing={0} height="100%">
      {/* Left Column */}
      <Column borderRight width={ "33%"}>
        <RigOperationTemplatesList rigOperationTemplates={rigOperationTemplates} setRigOperationRenameModalVisible={setRigOperationRenameModalVisible}/>
      </Column>

      {/* Right Column */}
      {activeRigOperationTemplate && (
        <Column width="60%">
          <RigOperationTemplateContent
            activeRigOperationTemplate={activeRigOperationTemplate}
            activities={activeRigOperationTemplate?.activities}
          />
        </Column>
      )}
    </Row>
  </Column>
    {rigOperationRenameModalVisible.visible && (
      <RigOperationTemplateRenameModal
        activeRigOperationTemplate={rigOperationRenameModalVisible.rename? activeRigOperationTemplate: undefined}
        setRigOperationRenameModalVisible={setRigOperationRenameModalVisible}
        rename={rigOperationRenameModalVisible.rename}
      />)}
      </>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { activeRigOperationTemplateId, rigOperationTemplates, isFetching, isAdding } = entities.companySettings;
  const activeRigOperationTemplate = rigOperationTemplates?.find(
    (rigOperationTemplate: IRigOperationTemplate) => rigOperationTemplate?.rigOperationTemplateId=== activeRigOperationTemplateId
  );

  return {
    isFetching,
    rigOperationTemplates,
    activeRigOperationTemplateId,
    activeRigOperationTemplate,
    isAdding

  };
};
const mapDispatchToProps = {
  getAllRigOperationTemplates,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(RigOperationTemplate);

export { Container as RigOperationTemplate };
