import { createSelector } from '@reduxjs/toolkit';
import { pageStatuses } from '~src/enums/projects';

const selectProjects = (entities) => entities.projects;

export const selectIsPageDisabled = createSelector(
  [selectProjects, (entities, name) => name],
  (projects, name) =>
    projects.overview?.pages?.[name] === pageStatuses.FINISHED,
);
