export const resultViewMode = Object.freeze({
  SEPARATE: 'separate',
  COMBINED: 'combined',
});

export const simulationChartType = Object.freeze({
  OPERATION_VS_DEPTH: 'operation_vs_depth',
  OPERATION_COST_VS_DEPTH: 'operation_cost_vs_depth',
  CUMULATIVE_PROBABILITY: 'cumulative_probability',
  CUMULATIVE_PROBABILITY_COST: 'cumulative_probability_cost',
  TOTAL_COST: 'total_cost',
  SIMULATION_TOTAL_TIME_AND_COST: 'simulation_total_time_and_cost',
  CUMULATIVE_PERCENTILES: 'cumulative_percentiles',
  PERCENTILES: 'percentiles',
  AVERAGE: 'average',
  AVERAGE_COST: 'average_cost',
  CUMULATIVE_COST_PERCENTILES: 'cumulative_cost_percentiles',
});

export const HierarchyType = Object.freeze({
  AutomationMethod: 'automationMethod',
  CountryName: 'countryName',
  Name: 'name',
  SiteName: 'siteName',
  UserName: 'userName',
  WellName: 'wellName',
  WellboreName: 'wellboreName',
  UseExisting: 'useExisting',
});

export const ProbabilityType = Object.freeze({
  P10: 10,
  P50: 50,
  P90: 90,
});

export const FIRST_INDEX = 0;
