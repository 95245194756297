import { combineReducers } from 'redux';
import activityModel from './activity-model/activity-model';
import ui from './ui';
import projects from './projects/projects';
import userSettings from './user-settings/user-settings';
import risks from './risks/risks';
import simulations from './simulations/simulations';
import costSetup from './cost-setup/cost-setup';
import operationCodes from './operation-codes/operation-codes';
import timeTracker from './time-tracker/time-tracker';
import wellDetails from './well-details/well-details';
import companies from './companies/companies';
import projectSettings from './project-settings/project-settings';
import offsetWells from './offset-wells/offset-wells';
import compareEstimates from './compare-estimates/compare-estimates';
import campaigns from './campaigns/campaigns';
import companySettings from './company-settings/company-settings';
import auditLog from './audit-log/audit-log';
import authorization from './authorization/authorization';
import sectionsDataStructure from './sections-data-structure/sections-data-structure';
import notes from './notes/notes';
import priceBook from './price-book/price-book';
import zendesk from './zendesk/zendesk';
import navigation from './navigation/navigation';
import hierarchy from './hierarchy/hierarchy';

export default combineReducers({
  activityModel,
  ui,
  projects,
  userSettings,
  risks,
  simulations,
  costSetup,
  operationCodes,
  timeTracker,
  wellDetails,
  companies,
  projectSettings,
  offsetWells,
  compareEstimates,
  campaigns,
  companySettings,
  auditLog,
  authorization,
  sectionsDataStructure,
  notes,
  priceBook,
  zendesk,
  navigation,
  hierarchy,
});
