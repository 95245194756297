  
import { IActivity } from 'src/common/interfaces/rig-operations-template.interfaces';
import { rigOperationTemplateActivitySchemaValidator as validate} from '~src/schemas/ajv-validators';
import { ErrorObject } from 'ajv';


export const validateRigOperationTemplateActivity = (activity: IActivity): Record<string, string | null> => {
    const validation = validate(activity);
    if (!validation) {
      const errors: Record<string, string | null> = {};
      validate.errors?.forEach((error: ErrorObject) => {
        const path = error.instancePath.substring(1).replace(/\//g, '.');
        errors[path] = error.message || 'Invalid value';
      });
      return errors;
    }
    return {};
  };