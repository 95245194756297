import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';

export const zendeskSlice = createSlice({
  name: 'zendesk',
  initialState: {
    domains: [],
    isLoaded: false, // widget loaded
    isChatOpen: false,
    isSubmitting: false,
    result: null,
    error: null,
  },
  reducers: {
    toggleChatOpen: (zendesk, action) => {
      zendesk.isChatOpen = action?.payload;
    },
    fetchDomainSuccess: (zendesk, action) => {
      const hasToken = action.payload?.length;
      if (hasToken) {
        zendesk.isLoaded = true;
        zendesk.domains = action.payload;
      } else {
        zendesk.isLoaded = false;
      }
    },
    fetchDomainsFailed: (zendesk) => {
      zendesk.isLoaded = true;
    },
  },
});

export const { toggleChatOpen, fetchDomainSuccess, fetchDomainsFailed } =
  zendeskSlice.actions;

export default zendeskSlice.reducer;

/**
    @param {object} body
    @param {string} body.feedback user provided feedback
    @param {string} body.subject subject we use for ticketing system in zendesk
 */

export const fetchDomains = () => {
  const url = '/api/integrations/external/zendesk/';
  return apiCallBegan({
    url,
    method: 'GET',
    onSuccess: (res) => fetchDomainSuccess(res),
    onError: (err) => fetchDomainsFailed(err),
  });
};
