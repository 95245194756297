import { useTranslation } from 'react-i18next';
import { Row, Column, Text } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { capitalize } from 'lodash';
import { distributionTypes } from '~src/enums/tasks';
import { ICalculatedTimes } from '~src/common/interfaces/activity-model';

interface TaskOffsetHeaderProps {
  calculatedTimes: ICalculatedTimes;
  task: string;
  operation: string;
  section: string;
}

const TaskOffsetHeader = ({
  calculatedTimes,
  task,
  operation,
  section,
}: TaskOffsetHeaderProps) => {
  const { distribution } = calculatedTimes;
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.section)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{section}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.operation)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{operation}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.activity)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{task}</Text>
          </Row>
        </Column>
        {distribution?.type && (
          <Column flex={false}>
            <Row spacing={0}>
              <Text bold>{t(translations.activityModel_distributionType)}</Text>
            </Row>
            <Row spacing={0}>
              <Text>{capitalize(distribution?.type)}</Text>
            </Row>
          </Column>
        )}
        {distribution?.type === distributionTypes.PERT && (
          <>
            <Column flex={false}>
              <Row spacing={0}>
                <Text bold>{t(translations.minimum)}</Text>
              </Row>
              <Row spacing={0}>
                <Text>{distribution?.A} h</Text>
              </Row>
            </Column>
            <Column flex={false}>
              <Row spacing={0}>
                <Text bold>{t(translations.mostLikely)}</Text>
              </Row>
              <Row spacing={0}>
                <Text>{distribution?.B} h</Text>
              </Row>
            </Column>
            <Column flex={false}>
              <Row spacing={0}>
                <Text bold>{t(translations.maximum)}</Text>
              </Row>
              <Row spacing={0}>
                <Text>{distribution?.C} h</Text>
              </Row>
            </Column>
          </>
        )}
        {distribution?.type === distributionTypes.UNIFORM && (
          <>
            <Column flex={false}>
              <Row spacing={0}>
                <Text bold>{t(translations.minimum)}</Text>
              </Row>
              <Row spacing={0}>
                <Text>{distribution?.A} h</Text>
              </Row>
            </Column>
            <Column flex={false}>
              <Row spacing={0}>
                <Text bold>{t(translations.maximum)}</Text>
              </Row>
              <Row spacing={0}>
                <Text>{distribution?.C} h</Text>
              </Row>
            </Column>
          </>
        )}
        {distribution?.type === distributionTypes.SPIKE && (
          <Column flex={false}>
            <Row spacing={0}>
              <Text bold>{t(translations.mostLikely)}</Text>
            </Row>
            <Row spacing={0}>
              <Text>{distribution?.B} h</Text>
            </Row>
          </Column>
        )}
      </Row>
    </>
  );
};

const Container = withErrorBoundary(TaskOffsetHeader, { isModal: true });

export { Container as TaskOffsetHeader };
