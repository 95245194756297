import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { isNumeric, round } from '@oliasoft-open-source/units';
import { apiCallBegan } from '~store/middleware/api/api';
import translations from '~src/internationalisation/translation-map.json';
import { HOURS_PER_DAY } from '~src/enums/general';
import { timeCostView } from '~src/enums/simulations';
import { isEmpty } from 'lodash';
import { toast } from '@oliasoft-open-source/react-ui-library';
import { simulationChartType } from '~src/enums/compare-estimates';

const initialState = {
  isFetching: false,
  simulationsResult: null,
  simulationStatus: {
    isSimulating: false,
    status: '',
    progress: '',
    message: '',
  },
};

const slice = createSlice({
  name: 'simulations',
  initialState,
  reducers: {
    runSimulation: (simulations) => {
      simulations.simulationStatus.isSimulating = true;
      simulations.simulationStatus.status = i18n.t(translations.initializing);
      simulations.simulationStatus.progress = 0;
      simulations.simulationStatus.message = i18n.t(
        translations.simulations_startingSimulations,
      );
    },

    simulationDone: (simulations) => {
      simulations.simulationStatus.isSimulating = false;
    },
    simulationFailed: (state) => {
      state.simulationStatus = { ...initialState.simulationStatus };
    },

    calcRequested: (calculations) => {
      calculations.isFetching = true;
    },

    calcReceived: (simulations, action) => {
      simulations.isFetching = false;
      simulations.simulationsResult = action.payload;
    },
    calcRequestFailed: (simulations) => {
      simulations.isFetching = false;
    },

    updateSimulationStatus: (simulations, action) => {
      simulations.simulationStatus.status = action.payload.status;
      simulations.simulationStatus.progress = action.payload.progress;
      simulations.simulationStatus.message = action.payload.message;
      if (
        action.payload.status === 'Finished' ||
        action.payload.status === 'Error'
      ) {
        simulations.simulationStatus.isSimulating = false;
      }
    },

    simulationCleanup: (simulations) => {
      simulations.simulationsResult = null;
    },
  },
});

export const {
  runSimulation,
  simulationDone,
  calcRequested,
  calcReceived,
  calcRequestFailed,
  updateSimulationStatus,
  simulationCleanup,
  simulationFailed,
} = slice.actions;
export default slice.reducer;

/**
 * Run calculations
 *
 * @param projectId
 */
export const runSimulations = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'POST',
    onStart: runSimulation.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.simulations_failedToRunSimulations),
        },
      });
      return {
        type: simulationFailed.type,
      };
    },
  });

/**
 * Get calculation list
 *
 * @param projectId
 */
export const getSimulations = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'GET',
    onStart: calcRequested.type,
    onSuccess: (response) => ({
      type: calcReceived.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.simulations_failedToGetSimulations),
        },
      });

      return {
        type: calcRequestFailed.type,
      };
    },
  });

/**
 * Get max probability
 *
 * @param simulation
 * @param chartType
 * @param probability
 * @param property
 * @returns {number|null}
 */
export const getMaxProbability = (
  simulation,
  chartType,
  probability,
  property,
) => {
  if (simulation?.[chartType]) {
    const data = simulation[chartType][probability][property];
    return property === timeCostView.TIME
      ? round(Math.max(...data) / HOURS_PER_DAY, 3)
      : round(Math.max(...data), 2);
  }
  return null;
};

/**
 * get cumulative percentile
 *
 * @param {object} simulation - simulation data
 * @param {string} charttype - type of chart
 * @param {number} probabilityindex - index for probability
 * @returns {number|null} - cumulative percentile or null
 */
export const getCumulativePercentile = (
  simulation,
  chartType,
  probabilityIndex,
  property,
) => {
  const chartData = simulation?.[chartType];
  if (!chartData) return null;

  const data =
    probabilityIndex === -1 ? chartData : chartData[probabilityIndex];
  return property === timeCostView.TIME
    ? round(data / HOURS_PER_DAY, 3)
    : round(data, 2);
};

/**
 * Get cumulative duration
 *
 * @param {object} simulation - simulation data
 * @param {string} probability - probability percentile
 * @param {number} dataEntity - time or cost
 * @returns {number | null} - cumulative percentile or null
 */
export const getDurationOrCost = (simulation, probability, dataEntity) => {
  if (isEmpty(simulation)) return null;
  const probabilityIndex = isNumeric(probability)
    ? simulation[simulationChartType.PERCENTILES]?.findIndex(
        (p) => p === +probability,
      )
    : -1;

  return dataEntity === 0
    ? getCumulativePercentile(
        simulation,
        probabilityIndex === -1
          ? simulationChartType.AVERAGE
          : simulationChartType.CUMULATIVE_PERCENTILES,
        probabilityIndex,
        timeCostView.TIME,
      )
    : getCumulativePercentile(
        simulation,
        probabilityIndex === -1
          ? simulationChartType.AVERAGE_COST
          : simulationChartType.CUMULATIVE_COST_PERCENTILES,
        probabilityIndex,
        timeCostView.COST,
      );
};

/**
 * Get meters per day
 *
 * @param {object} simulation - simulation data
 * @param {number} duration - cumulative duration
 * @param {string} probability - probability percentile
 * @returns {number | null} - meters per day or null
 */
export const getMetersPerDay = (simulation, duration, probability) => {
  if (duration === 0) {
    return 0;
  }

  const maxProbability = getMaxProbability(
    simulation,
    simulationChartType.OPERATION_VS_DEPTH,
    probability,
    timeCostView.DEPTH,
  );

  return maxProbability / duration;
};
