export enum CreateEstimateOptions {
  New = 'new',
  Copy = 'copy',
  Import = 'import',
}

export enum ProjectCategoryType {
  Actual = 'Actual',
  Prototype = 'Prototype',
}

export const pageStatuses = Object.freeze({
  ACTIVE: 'active',
  FINISHED: 'finished',
  DISABLED: 'disabled',
});

export const projectPages = Object.freeze({
  WELL_DETAILS: 'wellDetails',
  ACTIVITY_MODEL: 'activityModel',
  PROJECT_RISKS: 'projectRisks',
  COST_MODEL: 'costModel',
  REVIEW_RESULTS: 'reviewResults',
  TIME_TRACKER: 'timeTracker',
});
