import { Column, Flex, Heading, Table, Spacer  } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useMemo, useState} from 'react';
import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import { toNum } from '@oliasoft-open-source/units';
import translations from '~src/internationalisation/translation-map.json';
import { connect, ConnectedProps } from 'react-redux';
import { debounce } from 'lodash';
import{IActivity, IRigOperationTemplate} from '~src/common/interfaces/rig-operations-template.interfaces';
import { addRigOperationTemplateActivity , addInitialRigOperationTemplateActivity, removeRigOperationTemplateActivityItem, updateRigOperationTemplateActivityItem,rigOperationTemplateActivityUpdateSaved,  getAllRigOperationTemplates} from '~src/store/entities/company-settings/company-settings'; 
import type { TRootState } from '~src/store/store-types';
import { validateRigOperationTemplateActivity} from './utils';

interface RigOperationTemplateContentProps extends PropsFromRedux {
  activeRigOperationTemplate: IRigOperationTemplate;
  activities: IActivity[];
}

const RigOperationTemplateContent = ({
  isAdding,
  activeRigOperationTemplate,
  activities,
  removeRigOperationTemplateActivityItem,
  updateRigOperationTemplateActivityItem,
  rigOperationTemplateActivityUpdateSaved,
  getAllRigOperationTemplates,
  addInitialRigOperationTemplateActivity,
}: RigOperationTemplateContentProps) => {
  const rigOperationTemplateId = activeRigOperationTemplate?.rigOperationTemplateId;
  const { t } = useTranslation();

  const [activityErrors, setActivityErrors] = useState<Record<string, Record<string, string | null>>>({});

  const debounceUpdateActivityTable = useRef(
    debounce(updateRigOperationTemplateActivityItem, 500),
  );

  useEffect(() => {
    getAllRigOperationTemplates();
  }, [rigOperationTemplateId]);

  useEffect(() => {
    if (rigOperationTemplateId) {
      setActivityErrors({});
    }
  }, [rigOperationTemplateId]);

  const headerActions = [
    {
      icon: 'add',
      label: t(translations.add),
      onClick: () => addInitialRigOperationTemplateActivity(rigOperationTemplateId, activities),
      primary: true,
      disabled: isAdding,
    },
  ];

  const estimateList = [
    { label: 'm/h', value: estimateTypes.OPERATION_SPEED },
    { label: 'h', value: estimateTypes.OPERATION_TIME },
  ];

  const distributionList = [
    { label: distributionTypes.PERT, value: distributionTypes.PERT },
    { label: distributionTypes.SPIKE, value: distributionTypes.SPIKE },
    { label: distributionTypes.UNIFORM, value: distributionTypes.UNIFORM },
  ];

  const rowsParams = [
    { key: 'name', type: 'Input', name: t(translations.name), width: '70px' },
    { key: 'estimateType', type: 'Select', name: t(translations.unitType), width: '50px', options: estimateList },
    { key: 'distribution', type: 'Select', name: t(translations.activityModel_distributionType), width: '50px', options: distributionList },
    { key: 'min', type: 'Input', name: t(translations.minimum), width: '50px' },
    { key: 'most', type: 'Input', name: t(translations.mostLikely), width: '50px' },
    { key: 'max', type: 'Input', name: t(translations.maximum), width: '50px' },
  ];

  const isCellDisabled = (key: string, activity: IActivity): boolean => {
    const { distribution } = activity;
    return (
      ((key === 'min' || key === 'max') && distribution === distributionTypes.SPIKE) ||
      (key === 'most' && distribution === distributionTypes.UNIFORM)
    );
  };


const onChangeActivityList = (
  activity: IActivity,
  ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  propertyName: string,
) => {
  const newValue =
    propertyName === 'min' || propertyName === 'most' || propertyName === 'max'
      ? toNum(ev.target.value)
      : ev.target.value;
  const updatedActivity = { ...activity, [propertyName]: newValue };
  const errors = validateRigOperationTemplateActivity(updatedActivity);
  setActivityErrors((prevErrors) => ({
    ...prevErrors,
    [activity.rigOperationTemplateActivityId]: errors,
  }));
  rigOperationTemplateActivityUpdateSaved({ ...updatedActivity, rigOperationTemplateId });
  if (!Object.values(errors).some((error) => error !== null)) {
    debounceUpdateActivityTable.current({ ...updatedActivity, rigOperationTemplateId });
  }
};

  const tableRows = useMemo(() => {
    return activities.map((activity) => {
      const cells = rowsParams.map(({ key, type, width, options }) => {
        const isDisabled = isCellDisabled(key, activity);
        const value = isDisabled ? '' : activity[key as keyof IActivity];
        const error = !isDisabled ? activityErrors[activity.rigOperationTemplateActivityId]?.[key] : null;

        return {
          type,
          width,
          name: key,
          disabled: isDisabled,
          value,
          error,
          options,
          onChange: (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
            onChangeActivityList(activity, ev, key),
        };
      });

      return {
        cells,
        actions: [
          {
            label: t(translations.delete),
            icon: 'minus',
            onClick: () => removeRigOperationTemplateActivityItem(activity.rigOperationTemplateActivityId as string),
          },
        ],
      };
    });
  }, [activities, activityErrors]);

  return (
    <Column padding spacing={0}>
      <Flex justifyContent="space-between" gap>
        <Heading top>{activeRigOperationTemplate?.name}</Heading>
      </Flex>
      <Table
        table={{
          headers: [
            {
              actions: headerActions,
              cells: rowsParams.map(({ name }) => ({ value: name })),
            },
          ],
          rows: tableRows,
        }}
      />
      <Spacer />
    </Column>
  );
};

const mapStateToProps = ({ entities }: TRootState) => ({
  isAdding: entities.companySettings.isAdding,
});

const mapDispatchToProps = {
  addRigOperationTemplateActivity,
  removeRigOperationTemplateActivityItem,
  updateRigOperationTemplateActivityItem,
  rigOperationTemplateActivityUpdateSaved,
  getAllRigOperationTemplates,
  addInitialRigOperationTemplateActivity,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(RigOperationTemplateContent);
export { Container as RigOperationTemplateContent };
