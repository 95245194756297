import { ListSubheading, List, Spinner } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { connect, ConnectedProps } from 'react-redux';
import { rigOperationTemplatesItemSelected, addRigOperationTemplate , removeRigOperationTemplateItem, updateRigOperationTemplateItem} from '~src/store/entities/company-settings/company-settings';
import { IRigOperationTemplate } from '~src/common/interfaces/rig-operations-template.interfaces';
import type { TRootState } from '~src/store/store-types';

interface RigOperationRenameModalProps {
  visible: boolean;
  rename: boolean;
}
interface RigOperationTemplateListProps extends PropsFromRedux {
  rigOperationTemplates: IRigOperationTemplate[];
  setRigOperationRenameModalVisible: (props: RigOperationRenameModalProps) => void}

const RigOperationTemplatesList = ({  rigOperationTemplates, activeRigOperationTemplateId, rigOperationTemplatesItemSelected, isAdding , removeRigOperationTemplateItem, setRigOperationRenameModalVisible}: RigOperationTemplateListProps) => {
  const { t } = useTranslation();
    const rigOperationTemplatesList = (rigOperationTemplates?.map((rigOperationTemplate) => ({
    id: rigOperationTemplate.rigOperationTemplateId,
    name: rigOperationTemplate.name,
    active: rigOperationTemplate.rigOperationTemplateId===activeRigOperationTemplateId,
    onClick: () => rigOperationTemplatesItemSelected(rigOperationTemplate.rigOperationTemplateId),
    actions: [
      {
        label: 'More',
        subActions: [
          {
            icon: 'delete',
            label: t(translations.delete),
          },
          {
            icon: 'edit',
            label: t(translations.rename),
          },
        ],
      },
    ],  }))) || [];
  return (
    <>
      <ListSubheading
        item={{
          name: t(translations.rigOperationTemplates),
          actions: [
            {
              label: 'Add',
              icon: isAdding ? <Spinner tiny dark /> : 'add',
              disabled: isAdding,
              primary: true,
              onClick: () =>
                setRigOperationRenameModalVisible({visible:true, rename:false}),
            },
          ],
        }}
      />
      <List
        list={{
          items: rigOperationTemplatesList?.map((template) => ({
            ...template,
            actions: template.actions.map((action) => ({
              ...action,
              subActions: action.subActions.map((subAction) => ({
                ...subAction,
                onClick: () => {
                  if (subAction.icon === 'delete') {
                    removeRigOperationTemplateItem(template.id);
                  }
                  if (subAction.icon === 'edit') {
                    rigOperationTemplatesItemSelected(template.id)
                    setRigOperationRenameModalVisible({visible:true, rename:true});

                  }
                 },
              })),
            })),
          })),
        }}
        noHeader
      />
    </>
  );
};
const mapStateToProps = ({ entities }: TRootState) => {
  const { activeRigOperationTemplateId, isFetching, isAdding } = entities.companySettings;

  return {
    isFetching,
    activeRigOperationTemplateId,
    isAdding

  };
};

const mapDispatchToProps = { rigOperationTemplatesItemSelected, addRigOperationTemplate, updateRigOperationTemplateItem, removeRigOperationTemplateItem};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(RigOperationTemplatesList);

export { Container as RigOperationTemplatesList };
