import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '@oliasoft-open-source/react-ui-library';
import { SortTypes } from '~src/enums/sorting.ts';
import translations from '~src/internationalisation/translation-map.json';

enum IconTypes {
  SortAscending = 'sort ascending',
  SortDescending = 'sort descending',
  Sort = 'sort',
}

const UNSORTED_COLOR = 'var(--color-text-muted)';
const SORTED_COLOR = 'var(--color-text)';

const getIcon = (tooltip: string, iconType: IconTypes, color: string) => (
  <Tooltip text={tooltip}>
    <Icon icon={iconType} color={color} />
  </Tooltip>
);

const sortItems = (items: any[], attribute: string, isReversed: boolean) =>
  [...items].sort((a, b) =>
    isReversed
      ? b[attribute].localeCompare(a[attribute])
      : a[attribute].localeCompare(b[attribute]),
  );

/**
 * A function that sorts a list of items for List component based on a specified attribute and sort order.
 * It provides options for alphabetical sorting, reversed alphabetical sorting, and maintaining the original order.
 *
 * @throws {Error} Throws an error if an invalid sort type is provided.
 */
export const useListSorting = (
  items: any[],
  attribute: string = 'name',
  defaultSortOrder: SortTypes = SortTypes.Alphabetical,
) => {
  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const tooltips = Object.freeze({
    [SortTypes.Alphabetical]: t(translations.sortAscending),
    [SortTypes.AlphabeticalReversed]: t(translations.sortDescending),
    [SortTypes.Unsorted]: t(translations.noSort),
  });

  const createSortAction = (
    nextSortOrder: SortTypes,
    iconType: IconTypes,
    color: string,
  ) => ({
    label: 'Sort',
    icon: getIcon(tooltips[sortOrder], iconType, color),
    onClick: () => setSortOrder(nextSortOrder),
  });

  switch (sortOrder) {
    case SortTypes.AlphabeticalReversed:
      return {
        items: sortItems(items, attribute, true),
        sortAction: createSortAction(
          SortTypes.Alphabetical,
          IconTypes.SortDescending,
          SORTED_COLOR,
        ),
      };
    case SortTypes.Alphabetical:
      return {
        items: sortItems(items, attribute, false),
        sortAction: createSortAction(
          SortTypes.AlphabeticalReversed,
          IconTypes.SortAscending,
          SORTED_COLOR,
        ),
      };
    case SortTypes.Unsorted:
      return {
        items,
        sortAction: createSortAction(
          SortTypes.Alphabetical,
          IconTypes.Sort,
          UNSORTED_COLOR,
        ),
      };
    default:
      throw new Error(`Invalid sort type '${sortOrder}'`);
  }
};
