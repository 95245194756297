export enum HierarchyLevelType {
  Country = 'country',
  Field = 'field',
  Site = 'site',
  Well = 'well',
  Wellbore = 'wellbore',
  Design = 'design',
  Estimate = 'estimate',
}

export const IdFieldMap = new Map<HierarchyLevelType, string>([
  [HierarchyLevelType.Country, 'countryid'],
  [HierarchyLevelType.Field, 'fieldid'],
  [HierarchyLevelType.Site, 'siteid'],
  [HierarchyLevelType.Well, 'wellid'],
  [HierarchyLevelType.Wellbore, 'wellboreid'],
  [HierarchyLevelType.Design, 'designid'],
  [HierarchyLevelType.Estimate, 'estimateid'],
]);
