import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Input as GuiInput } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { isString } from 'lodash';
import { onChangeInput } from './helpers';
import { withErrorBoundary } from '../error-boundary/error-boundary';

/**
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.name - The name attribute of the input, used for form handling.
 * @param {Object} props.control - The control object provided by `useForm` hook from React Hook Form.
 * @param {Object} props.errors - An object containing validation errors related to the form.
 * @param {string} [props.type='string'] - The type of the input field. Default is 'string'.
 * @param {number|null} [props.groupOrder=null] - The order of the input in a grouped input scenario.
 * @param {number} [props.width=0] - The width of the input field.
 * @param {function|null} [props.onChange=null] - Optional custom onChange handler for the input.
 * @param {boolean} [props.disabled=false] - Boolean indicating if the input is disabled.
 * @param {string} [props.placeholder=''] - Placeholder text for the input field.
 */
const RawInput = ({
  name,
  control,
  errors,
  type = 'string',
  groupOrder = null,
  width = 0,
  onChange = null,
  disabled = false,
  placeholder = '',
}) => {
  const { t } = useTranslation();

  let error = null;
  if (translations[errors[name]]) {
    error = t(errors[name]);
  } else if (isString(errors[name])) {
    error = errors[name];
  } else if (errors[name]?.message) {
    error = errors[name].message;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...props } = field;
        return (
          <GuiInput
            {...props}
            onChange={(e) => {
              onChangeInput(e, type, fieldOnChange, onChange);
            }}
            error={error}
            groupOrder={groupOrder}
            width={width}
            disabled={disabled}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export const Input = withErrorBoundary(RawInput);
