import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const projectSettings = createSlice({
  name: 'projectSettings',
  initialState: {
    isAdding: false,
    isFetching: false,
    showProjectSettingsModal: false,
    currencies: [],
    settings: {},
  },
  reducers: {
    projectSettingsRequested: (projectSettings) => {
      projectSettings.isFetching = true;
    },
    projectSettingsReceived: (projectSettings, action) => {
      projectSettings.isFetching = false;
      projectSettings.settings = action.payload.settings;
      projectSettings.currencies = action.payload.currencies;
    },
    projectSettingsRequestFailed: (projectSettings) => {
      projectSettings.isFetching = false;
    },
    projectSettingsSaveRequested: (projectSettings) => {
      projectSettings.isAdding = true;
    },
    projectSettingsSaved: (projectSettings, action) => {
      projectSettings.settings = action.payload;
      projectSettings.isAdding = false;
      projectSettings.showProjectSettingsModal = false;
    },
    projectSettingsSaveRequestFailed: (projectSettings) => {
      projectSettings.isAdding = false;
    },
    showProjectSettingsModalUpdated: (projectSettings, action) => {
      projectSettings.showProjectSettingsModal = action.payload;
    },
    projectCategoryUpdated: (projectSettings, action) => {
      projectSettings.isFetching = false;
      projectSettings.settings.category = action.payload;
    },
    projectCategoryUpdateFailed: (projectSettings) => {
      projectSettings.isFetching = false;
    },
  },
});

export const {
  projectSettingsRequested,
  projectSettingsReceived,
  projectSettingsRequestFailed,
  projectSettingsSaveRequested,
  projectSettingsSaved,
  projectSettingsSaveRequestFailed,
  showProjectSettingsModalUpdated,
  projectCategoryUpdated,
  projectCategoryUpdateFailed,
} = projectSettings.actions;
export default projectSettings.reducer;

/**
 * Get settings
 *
 * @param projectId
 */
export const getProjectSettings = (projectId) =>
  apiCallBegan({
    url: `/api/project-settings/${projectId}`,
    method: 'GET',
    onStart: projectSettingsRequested.type,
    onSuccess: projectSettingsReceived.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.projectSettings_failedToGetProjectSettings,
          ),
        },
      });

      return {
        type: projectSettingsRequestFailed.type,
      };
    },
  });

/**
 * Save settings
 *
 * @param projectId
 * @param data
 */
export const saveProjectSettings = (projectId, data) =>
  apiCallBegan({
    url: `/api/project-settings/${projectId}`,
    method: 'POST',
    data,
    onStart: projectSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: projectSettingsSaved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.projectSettings_failedToSaveProjectSettings,
          ),
        },
      });

      return {
        type: projectSettingsSaveRequestFailed.type,
      };
    },
  });

/**
 * Update project category
 *
 * @param projectId
 * @param category
 */
export const updateProjectCategory = (projectId, category) =>
  apiCallBegan({
    url: `/api/project-settings/category/${projectId}`,
    method: 'POST',
    data: { category },
    onStart: projectSettingsRequested.type,
    onSuccess: projectCategoryUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.projectSettings_failedToUpdateProjectSettings,
          ),
        },
      });
      return {
        type: projectCategoryUpdateFailed.type,
      };
    },
  });
