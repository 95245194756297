import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { operationCodesTypes, operationCodesForms } from '~src/enums/settings';

const operationCodes = createSlice({
  name: 'operationCodes',
  initialState: {
    isSaving: false,
    isFetching: false,
    codesLists: [],
    codes: [],
    subCodes: [],
    activityCodes: [],
    activeCodesList: null,
    activeForm: null,
    selectedId: null,
    codeType: null,
  },
  reducers: {
    codesRequested: (operationCodes) => {
      operationCodes.isFetching = true;
    },
    codesReceived: (operationCodes, action) => {
      const { codes } = action.payload;
      operationCodes.isFetching = false;
      operationCodes.codesLists = action.payload.codesLists;
      operationCodes.activeCodesList = action.payload.activeCodesList;
      operationCodes.codes = codes.filter(
        (code) => code.type === operationCodesTypes.OPERATION,
      );
      operationCodes.subCodes = codes
        .filter((code) => code.type === operationCodesTypes.SUB_OPERATION)
        .concat(
          codes.filter((code) => code.type === operationCodesTypes.ACTIVITY),
        );
    },
    codesRequestFailed: (operationCodes) => {
      operationCodes.isFetching = false;
    },
    codesListSaveRequested: (operationCodes) => {
      operationCodes.isFetching = true;
    },
    codesListSaved: (operationCodes, action) => {
      operationCodes.isFetching = false;
      operationCodes.codesLists = operationCodes.codesLists.map((item) => {
        item.active = false;
        return item;
      });

      const existingIndex = operationCodes.codesLists.findIndex(
        (item) => item.codesListId === action.payload.codesListId,
      );

      if (existingIndex === -1) {
        operationCodes.codesLists.push(action.payload);
      } else {
        operationCodes.codesLists[existingIndex] = action.payload;
      }

      operationCodes.activeCodesList = action.payload;
    },
    codesListSaveRequestFailed: (operationCodes) => {
      operationCodes.isFetching = false;
    },
    codesListCloneRequested: (operationCodes) => {
      operationCodes.isFetching = true;
    },
    codeSelected: (operationCodes, action) => {
      operationCodes.codes = operationCodes.codes.map((item) => {
        item.active = item.codeId === action.payload;
        return item;
      });
      operationCodes.subCodes = operationCodes.subCodes.map((item) => {
        item.active = false;
        return item;
      });
      operationCodes.codeType = operationCodesTypes.OPERATION;
      operationCodes.activeForm = operationCodesForms.OPERATION_CODES;
    },
    subCodeSelected: (operationCodes, action) => {
      operationCodes.codeType = operationCodesTypes.SUB_OPERATION;
      operationCodes.subCodes = operationCodes.subCodes.map((item) => {
        item.active = item.codeId === action.payload;
        return item;
      });
    },
    codesListActivationRequested: (operationCodes) => {
      operationCodes.isFetching = true;
    },
    codesListActivated: (operationCodes, action) => {
      operationCodes.isFetching = false;
      operationCodes.activeCodesList = action.payload;
      operationCodes.activeForm = null;
    },
    codesListActivationRequestFailed: (operationCodes) => {
      operationCodes.isFetching = false;
    },
    codesListRemoveRequested: (operationCodes) => {
      operationCodes.isFetching = true;
    },
    codesListRemoved: (operationCodes, action) => {
      const { codesLists } = operationCodes;
      const index = codesLists.findIndex(
        (codesList) => codesList.codesListId === action.payload.codesListId,
      );
      delete codesLists[index];
      operationCodes.activeCodesList = action.payload.codesList;
      operationCodes.codesLists = codesLists.filter((i) => i);
      operationCodes.isFetching = false;
      operationCodes.activeForm = null;
    },
    codesListRemoveRequestFailed: (operationCodes) => {
      operationCodes.isFetching = false;
    },
    codesSaveRequested: (operationCodes) => {
      operationCodes.isSaving = true;
    },
    codesSaved: (operationCodes, action) => {
      operationCodes.isSaving = false;
      const key =
        action.payload.type === operationCodesTypes.OPERATION
          ? 'codes'
          : 'subCodes';
      operationCodes[key] = operationCodes[key].map((item) => {
        item.active = false;
        return item;
      });

      const existingIndex = operationCodes[key].findIndex(
        (item) => item.codeId === action.payload.codeId,
      );
      action.payload.active = true;
      if (existingIndex === -1) {
        operationCodes[key].push(action.payload);
      } else {
        operationCodes[key][existingIndex] = action.payload;
      }
    },
    codesSaveRequestFailed: (operationCodes) => {
      operationCodes.isSaving = false;
    },
    codeRemoveRequested: (operationCodes) => {
      operationCodes.isFetching = true;
    },
    codeRemoved: (operationCodes, action) => {
      const { codes, subCodes } = operationCodes;
      for (const { codeId } of action.payload) {
        const codesIndex = codes.findIndex((item) => item.codeId === codeId);
        const subCodesIndex = subCodes.findIndex(
          (item) => item.codeId === codeId,
        );
        if (codesIndex !== -1) {
          codes.splice(codesIndex, 1);
        }
        if (subCodesIndex !== -1) {
          subCodes.splice(subCodesIndex, 1);
        }
      }
      operationCodes.codes = codes.filter((i) => i);
      operationCodes.subCodes = subCodes.filter((i) => i);
      operationCodes.isFetching = false;
    },
    codeRemoveRequestFailed: (operationCodes) => {
      operationCodes.isFetching = false;
    },
    deleteCodeClicked: (operationCodes, action) => {
      operationCodes.selectedId = action.payload;
      operationCodes.activeForm = null;
    },
    addCodeClicked: (operationCodes) => {
      operationCodes.codes = operationCodes.codes.map((item) => {
        item.active = false;
        return item;
      });
      operationCodes.activeForm = operationCodesForms.OPERATION_CODES;
      operationCodes.codeType = operationCodesTypes.OPERATION;
    },
    activeFormUpdated: (operationCodes, action) => {
      operationCodes.activeForm = action.payload;
    },
    addSubCodeClicked: (operationCodes) => {
      operationCodes.subCodes = operationCodes.subCodes.map((item) => {
        item.active = false;
        return item;
      });
      operationCodes.activeForm = operationCodesForms.OPERATION_CODES;
      operationCodes.codeType = operationCodesTypes.SUB_OPERATION;
    },
    addActivityCodeClicked: (operationCodes) => {
      operationCodes.subCodes = operationCodes.subCodes.map((item) => {
        item.active = false;
        return item;
      });
      operationCodes.activeForm = operationCodesForms.OPERATION_CODES;
      operationCodes.codeType = operationCodesTypes.ACTIVITY;
    },
    editCodeClicked: (operationCodes) => {
      operationCodes.activeForm = operationCodesForms.OPERATION_CODES;
      operationCodes.codeType = operationCodesTypes.OPERATION;
    },
  },
});

export const {
  codesRequested,
  codesReceived,
  codesRequestFailed,
  codeSelected,
  subCodeSelected,
  codesListSaveRequested,
  codesListSaved,
  codesListSaveRequestFailed,
  codesListActivationRequested,
  codesListActivated,
  codesListActivationRequestFailed,
  codesListRemoved,
  codesListRemoveRequested,
  codesListRemoveRequestFailed,
  codesSaveRequested,
  codesSaved,
  codesSaveRequestFailed,
  codeRemoveRequested,
  codeRemoved,
  codeRemoveRequestFailed,
  deleteCodeClicked,
  activeFormUpdated,
  addCodeClicked,
  addSubCodeClicked,
  addActivityCodeClicked,
  editCodeClicked,
  codesListCloneRequested,
} = operationCodes.actions;
export default operationCodes.reducer;

export const initialCodesList = {
  name: '',
  active: true,
};

export const initialCode = {
  code: '',
  description: '',
  parentId: null,
};

/**
 * Build code name from code and description
 *
 * @param code
 * @returns {string}
 */
export const getCodeName = (code) =>
  code ? `${code.code}: ${code.description}` : '';

/**
 * Get codes
 */
export const getCodes = () =>
  apiCallBegan({
    url: '/api/user-settings/operation-codes',
    method: 'GET',
    onStart: codesRequested.type,
    onSuccess: codesReceived.type,
    onError: codesRequestFailed.type,
  });

/**
 * Save codes list
 *
 * @param data
 */
export const saveCodesList = (data) =>
  apiCallBegan({
    url: '/api/user-settings/codes-lists',
    method: 'POST',
    data,
    onStart: codesListSaveRequested.type,
    onSuccess: (response) => ({
      type: codesListSaved.type,
      payload: response,
    }),
    onError: codesListSaveRequestFailed.type,
  });

export const addInitialCodesList = (name) =>
  saveCodesList({ ...initialCodesList, name });

/**
 * Activate codes list
 *
 * @param data
 */
export const activateCodesList = (data) =>
  apiCallBegan({
    url: '/api/user-settings/activate-codes-list',
    method: 'POST',
    data,
    onStart: codesListActivationRequested.type,
    onSuccess: (response) => ({
      type: codesListActivated.type,
      payload: response,
    }),
    onError: codesListActivationRequestFailed.type,
  });

/**
 * Clone codes list
 *
 * @param codesListId
 * @param name
 */
export const cloneCodesList = (codesListId, name) =>
  apiCallBegan({
    url: '/api/user-settings/codes-lists/clone',
    method: 'POST',
    data: { codesListId, name },
    onStart: codesListCloneRequested.type,
    onSuccess: (response) => ({
      type: codesReceived.type,
      payload: response,
    }),
  });

/**
 * Remove codes list
 *
 * @param id
 */
export const removeCodesList = (id) =>
  apiCallBegan({
    url: `/api/user-settings/codes-lists/${id}`,
    method: 'DELETE',
    onStart: codesListRemoveRequested.type,
    onSuccess: (response) => ({
      type: codesListRemoved.type,
      payload: response,
    }),
    onError: codesListRemoveRequestFailed.type,
  });

/**
 * Save codes list
 *
 * @param data
 * @param codesListId
 * @param parentId
 */
export const saveCode = (data, codesListId, parentId) =>
  apiCallBegan({
    url: '/api/user-settings/codes',
    method: 'POST',
    data: { ...data, codesListId, parentId },
    onStart: codesSaveRequested.type,
    onSuccess: (response) => ({
      type: codesSaved.type,
      payload: response,
    }),
    onError: codesSaveRequestFailed,
  });

/**
 * Remove code
 *
 * @param id
 */
export const removeCode = (id) =>
  apiCallBegan({
    url: `/api/user-settings/codes/${id}`,
    method: 'DELETE',
    onStart: codeRemoveRequested.type,
    onSuccess: (response) => ({
      type: codeRemoved.type,
      payload: response,
    }),
    onError: codeRemoveRequestFailed.type,
  });
