import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import {
  createHierarchyItem,
  updateHierarchyItem,
} from '~store/entities/hierarchy/hierarchy';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { projectEstimatesSchemaValidator } from '~schemas/ajv-validators';
import { generatePath, navigateToPath } from '~store/navigation/navigation';
import { routes } from '~routes/routes';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { IDesign, IEstimate } from '~common/interfaces/hierarchy.interfaces.ts';

type EstimateModalProps = PropsFromRedux & {
  companyId: string;
  activeDesign: IDesign | null;
  estimates: IEstimate[];
  isAdding: boolean;
  editItem: IEstimate | null;
  setEditItem: (data: IEstimate | null) => void;
  itemModalVisibleUpdated: (data: {
    type: HierarchyLevelType;
    value: boolean;
  }) => void;
};

const EstimateModal = ({
  companyId,
  itemModalVisibleUpdated,
  createHierarchyItem,
  updateHierarchyItem,
  activeDesign,
  editItem,
  setEditItem,
  isAdding,
  estimates,
  navigateToPath,
}: EstimateModalProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      design: activeDesign?.name,
      designid: activeDesign?.designid,
      name: editItem?.name || '',
      estimateid: editItem?.estimateid || null,
    },
    resolver: getResolver(projectEstimatesSchemaValidator),
  });

  useEffect(() => {
    const estimate = estimates.find((item) => item.active);
    if (estimate) {
      navigateToPath(
        generatePath(
          routes.overview,
          {
            company: companyId,
            project: estimate.estimateid,
          },
          true,
        ),
      );
      itemModalVisibleUpdated({
        type: HierarchyLevelType.Estimate,
        value: false,
      });
    }
  }, [estimates]);

  const onSubmit = handleSubmit(async (data) => {
    delete data.design;
    if (editItem) {
      updateHierarchyItem(
        companyId,
        HierarchyLevelType.Estimate,
        data.estimateid,
        data,
      );
    } else {
      createHierarchyItem(companyId, HierarchyLevelType.Estimate, {
        name: data.name,
        designid: activeDesign?.designid,
      });
    }

    setEditItem(null);
  });
  const onClose = () => {
    setEditItem(null);
    itemModalVisibleUpdated({
      type: HierarchyLevelType.Estimate,
      value: false,
    });
  };

  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          heading: editItem
            ? t(translations.projects_editEstimate)
            : t(translations.projects_addNewEstimate),
          width: '600px',
          content: (
            <form>
              <Field label={t(translations.design)} labelLeft labelWidth="80px">
                <Input
                  name="design"
                  control={control}
                  errors={errors}
                  disabled={true}
                />
              </Field>
              <Field
                label={t(translations.estimate)}
                labelLeft
                labelWidth="80px"
              >
                <Input
                  name="name"
                  control={control}
                  errors={errors}
                  disabled={isAdding}
                />
              </Field>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = {
  createHierarchyItem,
  updateHierarchyItem,
  navigateToPath,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(EstimateModal), {
  isModal: true,
});

export { Container as EstimateModal };
