import { List, Loader, Spinner } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';

const FetchingList = ({ testId, name }: { testId: string; name: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <List
        testId={testId}
        list={{ name, actions: [], items: [] }}
        scrollDetails={{
          scrollable: false,
          hideScrollbar: true,
        }}
      />
      <Loader text={t(translations.fetching)} theme="light" height="300px">
        <Spinner dark />
      </Loader>
    </>
  );
};

export { FetchingList };
