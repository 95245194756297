import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
  FileInput as GuiFileInput,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '../error-boundary/error-boundary';

/**
 * @param {Object} props - Properties passed to the component
 * @param {string} props.name - The name of the form field
 * @param {Object} props.control - Control object from the form handling library
 * @param {Object} props.errors - Object containing validation errors
 * @param {string} [props.accept='.json'] - Accepted file MIME types
 * @param {Object|null} [props.file=null] - The file object
 * @param {string|null} [props.width=null] - Width of the file input component
 * @param {function|null} [props.onChange=null] - Callback function called when file input changes
 * @returns {JSX.Element} A JSX component rendering a file input with error handling
 */
const RawFileInput = ({
  name,
  control,
  errors,
  accept = '.json',
  file = null,
  width = null,
  onChange = null,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, props } = field;
        let error = errors[name]?.message || null;
        if (typeof errors[name] === 'string') {
          error = translations[errors[name]]
            ? t(errors[name])
            : errors[name] || null;
        }
        return (
          <div>
            {error && <Text error>{error}</Text>}
            <GuiFileInput
              {...props}
              accept={accept}
              file={file}
              onChange={(e) => {
                fieldOnChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              width={width}
            />
          </div>
        );
      }}
    />
  );
};

export const FileInput = withErrorBoundary(RawFileInput);
