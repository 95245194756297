import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from '@oliasoft-open-source/react-ui-library';
import {
  getItems,
  itemSelected,
  listCleaned,
} from '~store/entities/hierarchy/hierarchy';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  IDesign,
  IWellbore,
  ISelectedProject,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { useListSorting } from '~common/sorting/use-list-sorting.tsx';
import { FetchingList } from '~common/lists/fetching-list.tsx';
import { isEmpty } from 'lodash';
import { updateProjectHierarchy } from '../hierarchy-utils/local-storage-util';

type DesignsListProps = PropsFromRedux & {
  companyId: string;
  designs: IDesign[];
  activeWellbore: IWellbore | null;
  isDesignFetching: boolean;
  addButtonDisabled: boolean;
  isAdding: boolean;
  onClickDelete: (id: string, type: HierarchyLevelType) => void;
  itemModalVisibleUpdated: (data: {
    type: HierarchyLevelType;
    value: boolean;
  }) => void;
  setEditItem: (data: IDesign | null) => void;
  selectedProject: ISelectedProject;
};

const DesignsList = ({
  companyId,
  designs,
  onClickDelete,
  activeWellbore,
  itemModalVisibleUpdated,
  getItems,
  itemSelected,
  listCleaned,
  setEditItem,
  addButtonDisabled,
  isDesignFetching,
  selectedProject,
}: DesignsListProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (activeWellbore?.wellboreid) {
      const fetchData = async () => {
        await getItems(
          companyId,
          HierarchyLevelType.Design,
          activeWellbore.wellboreid,
        );
        if (!isEmpty(selectedProject.designId)) {
          itemSelected({
            id: selectedProject.designId,
            type: HierarchyLevelType.Design,
          });
        }
      };
      fetchData();
    }

    return () => {
      listCleaned(HierarchyLevelType.Design);
    };
  }, [companyId, activeWellbore]);
  const { items, sortAction } = useListSorting(designs);

  if (isDesignFetching) {
    return <FetchingList testId="design-list" name={t(translations.design)} />;
  }

  return (
    <List
      testId="design-list"
      list={{
        name: t(translations.design),
        actions: [
          sortAction,
          {
            label: 'Add',
            icon: 'add',
            onClick: () =>
              itemModalVisibleUpdated({
                type: HierarchyLevelType.Design,
                value: true,
              }),
            disabled: addButtonDisabled || isDesignFetching,
          },
        ],
        items: items.map((design) => {
          const { childrenCount } = design.metadata;

          return {
            id: design.designid,
            name: design.name,
            metadata: `${childrenCount} ${
              +childrenCount === 1
                ? t(translations.estimate)
                : t(translations.estimates)
            }`,
            active: design.active,
            onClick: () => {
              itemSelected({
                id: design.designid,
                type: HierarchyLevelType.Design,
              });
              updateProjectHierarchy(
                'designId',
                design.designid,
                selectedProject,
              );
            },
            actions: [
              {
                label: 'Update',
                icon: 'edit',
                onClick: () => {
                  setEditItem(design);
                  itemModalVisibleUpdated({
                    type: HierarchyLevelType.Design,
                    value: true,
                  });
                },
              },
              {
                label: 'Delete',
                icon: 'delete',
                onClick: () =>
                  onClickDelete(design.designid, HierarchyLevelType.Design),
              },
            ],
          };
        }),
      }}
      stickyHeader
      expanding
      scrollDetails={{
        scrollable: true,
        hideScrollbar: true,
      }}
    />
  );
};

const mapDispatchToProps = { getItems, itemSelected, listCleaned };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(DesignsList));

export { Container as DesignsList };
