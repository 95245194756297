import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import {
  deleteHierarchyItem,
  deleteModalVisibleUpdated,
} from '~store/entities/hierarchy/hierarchy';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { HierarchyLevelType } from '~src/enums/hierarchy.ts';
import type { TRootState } from '~store/store-types';

type DeleteModalProps = PropsFromRedux & {
  companyId: string;
};

const DeleteModal = ({
  deleteHierarchyItem,
  deleteModalVisibleUpdated,
  selectedId,
  selectedType,
  companyId,
}: DeleteModalProps) => {
  const { t } = useTranslation();
  const onClose = () => deleteModalVisibleUpdated(false);
  const onDelete = () => {
    deleteHierarchyItem(
      companyId,
      selectedType as HierarchyLevelType,
      selectedId as string,
    );
    localStorage.removeItem('activeProject');
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          heading: `${t(translations.delete)} ${selectedType}`,
          content: (
            <>
              {t(translations.projects_areYouSureYouWantToDeleteThis, {
                selectedList: selectedType,
              })}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
                testId="confirm-delete"
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const {
    hierarchy: { selectedId, selectedType },
  } = entities;

  return {
    selectedId,
    selectedType,
  };
};
const mapDispatchToProps = { deleteHierarchyItem, deleteModalVisibleUpdated };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(DeleteModal), { isModal: true });

export { Container as DeleteModal };
