import { ITableProps } from '@oliasoft-open-source/react-ui-library';
import { cleanNum } from '@oliasoft-open-source/units';

export const highlightTheLowestInColumn = (
  data: ITableProps['table']['rows'],
) => {
  const dataCopy = data?.map((row) => ({
    cells: row.cells.map((cell) => ({ ...cell })),
  }));

  const transposedData = dataCopy?.[0].cells.map((_, colIndex) =>
    dataCopy.map((row) => row.cells[colIndex]),
  );

  transposedData?.slice(1).forEach((column) => {
    const filterOutNullValues = column.filter(
      (cell: any) => cell.value !== null && cell.value !== '',
    );
    const minValue = Math.min(
      ...filterOutNullValues.map((cell: any) => cleanNum(cell.value)),
    );
    column.forEach((cell: any) => {
      if (cleanNum(cell.value) === minValue) {
        cell.style = { backgroundColor: 'var(--color-success-100)' };
      }
    });
  });
  return dataCopy;
};
