import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List, Menu, Button } from '@oliasoft-open-source/react-ui-library';
import {
  exportProject,
  groupedEstimates,
} from '~store/entities/projects/projects';
import {
  getItems,
  itemSelected,
  listCleaned,
  duplicateProject,
} from '~store/entities/hierarchy/hierarchy';
import { HierarchyLevelType } from '~src/enums/hierarchy';
import { ProjectCategoryType } from '~src/enums/projects';
import translations from '~src/internationalisation/translation-map.json';
import { routes } from '~routes/routes';
import { generatePath, navigateToPath } from '~store/navigation/navigation';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  IDesign,
  IEstimate,
  ISelectedProject,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { useListSorting } from '~common/sorting/use-list-sorting.tsx';
import { FetchingList } from '~common/lists/fetching-list.tsx';

type EstimateListProps = PropsFromRedux & {
  companyId: string;
  estimates: IEstimate[];
  activeDesign: IDesign | null;
  isEstimateFetching: boolean;
  addButtonDisabled: boolean;
  onClickDelete: (id: string, type: HierarchyLevelType) => void;
  itemModalVisibleUpdated: (data: {
    type: HierarchyLevelType;
    value: boolean;
  }) => void;
  setEditItem: (data: IEstimate | null) => void;
  onUpdateProjectCategory: (id: string, category: string) => void;
  selectedProject: ISelectedProject;
};

const EstimateList = ({
  companyId,
  estimates,
  activeDesign,
  onClickDelete,
  itemModalVisibleUpdated,
  setEditItem,
  navigateToPath,
  getItems,
  itemSelected,
  listCleaned,
  addButtonDisabled,
  exportProject,
  isEstimateFetching,
  duplicateProject,
  onUpdateProjectCategory,
}: EstimateListProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (activeDesign?.designid) {
      getItems(companyId, HierarchyLevelType.Estimate, activeDesign.designid);
    }

    return () => {
      listCleaned(HierarchyLevelType.Estimate);
    };
  }, [companyId, activeDesign]);
  const { items, sortAction } = useListSorting(estimates);

  if (isEstimateFetching) {
    return (
      <FetchingList testId="estimate-list" name={t(translations.estimate)} />
    );
  }

  const listItems: any = [];
  const categoryLabels = {
    [ProjectCategoryType.Actual]: t(translations.actual),
    [ProjectCategoryType.Prototype]: t(translations.prototype),
  };

  const listItemFromEstimate = (estimate: IEstimate) => {
    return {
      id: estimate.estimateid,
      name: estimate.name,
      metadata: estimate.design,
      active: estimate.active,
      onClick: () => {
        itemSelected({
          type: HierarchyLevelType.Estimate,
          id: estimate.estimateid,
        });

        navigateToPath(
          generatePath(routes.overview.fullPath, {
            company: estimate.companyid,
            project: estimate.estimateid,
          }),
        );
      },
      actions: [
        {
          childComponent: (
            <Menu
              menu={{
                label: t(translations.more),
                sections: [
                  {
                    label: t(translations.update),
                    icon: 'edit',
                    type: 'Option',
                    onClick: () => {
                      setEditItem(estimate);
                      itemModalVisibleUpdated({
                        type: HierarchyLevelType.Estimate,
                        value: true,
                      });
                    },
                  },
                  {
                    label: t(translations.duplicate),
                    icon: 'duplicate',
                    type: 'Option',
                    onClick: () => {
                      duplicateProject(estimate);
                    },
                  },
                  {
                    type: 'Divider',
                  },
                  {
                    label: t(translations.downloadAsJson),
                    icon: 'download',
                    type: 'Option',
                    onClick: () =>
                      exportProject(estimate.estimateid, estimate.name),
                  },
                  {
                    type: 'Divider',
                  },
                  {
                    label: t(translations.delete),
                    icon: 'delete',
                    type: 'Option',
                    onClick: () =>
                      onClickDelete(
                        estimate.estimateid,
                        HierarchyLevelType.Estimate,
                      ),
                  },
                  {
                    type: 'Divider',
                  },
                  // @ts-ignore TODO: add proper fix
                  estimate.category === ProjectCategoryType.Prototype && {
                    label: t(translations.projects_setAsIdentifier, {
                      identifier: t(translations.actual),
                    }),
                    type: 'Option',
                    onClick: () =>
                      onUpdateProjectCategory(
                        estimate.estimateid,
                        ProjectCategoryType.Actual,
                      ),
                  },
                  // @ts-ignore
                  estimate.category !== ProjectCategoryType.Prototype && {
                    label: t(translations.projects_setAsIdentifier, {
                      identifier: t(translations.prototype),
                    }),
                    type: 'Option',
                    onClick: () =>
                      onUpdateProjectCategory(
                        estimate.estimateid,
                        ProjectCategoryType.Prototype,
                      ),
                  },
                ],
                trigger: 'Component',
                component: (
                  <Button small basic round colored="muted" icon="menu" />
                ),
              }}
            />
          ),
        },
      ],
    };
  };

  const estimateGroups: any = groupedEstimates(items);

  Object.values(ProjectCategoryType).forEach((category) => {
    const estimateGroup = estimateGroups[category];
    if (!estimateGroup) {
      return;
    }
    listItems.push({
      id: `heading-${category}`,
      name: categoryLabels[category],
      type: 'Heading',
      metaCount: estimateGroup.length,
    });
    listItems.push(...estimateGroup.map(listItemFromEstimate));
  });

  return (
    <List
      testId="estimate-list"
      list={{
        name: t(translations.estimate),
        actions: [
          sortAction,
          {
            label: 'Add',
            icon: 'add',
            onClick: () =>
              itemModalVisibleUpdated({
                type: HierarchyLevelType.Estimate,
                value: true,
              }),
            disabled: addButtonDisabled || isEstimateFetching,
          },
        ],
        items: listItems,
      }}
      stickyHeader
      expanding
      scrollDetails={{
        scrollable: true,
        hideScrollbar: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  navigateToPath,
  exportProject,
  duplicateProject,
  getItems,
  itemSelected,
  listCleaned,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(EstimateList));

export { Container as EstimateList };
