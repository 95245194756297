import { round, isValidNum } from '@oliasoft-open-source/units';
import { HOURS_PER_DAY } from '~src/enums/general';

const calcDuration = (list, curIdx) => {
  return round(
    list
      .slice(0, curIdx + 1)
      .reduce((sum, el) => sum + Number(el.actual || 0), 0) / HOURS_PER_DAY,
  );
};
export const generateActualChartLine = (list, t, translations) => {
  const filteredOperationList = list?.filter(
    (operation) =>
      isValidNum(operation?.actual) && isValidNum(operation?.actualDepth),
  );
  return {
    label: t(translations.actual),
    data: [
      { x: 0, y: filteredOperationList?.[0]?.actualDepth, label: '' },
      ...filteredOperationList.map((operation, idx) => {
        return operation.activities.every(
          (activity) => activity.actualDepth === null,
        )
          ? {}
          : {
              x: calcDuration(filteredOperationList, idx),
              y: Number(operation.actualDepth),
              label: operation.name,
            };
      }),
    ],
  };
};
