/* eslint-disable import/no-cycle */
import EPSGLookup from '@oliasoft/gis-library/results/epsg-lookup.json';
import { areAllArgumentsNumeric, isOffsetType } from '@oliasoft/gis-core';

/**
 * Check if provided EPSG code can be used by our GIS system
 * @param {import('common/gis/gis.interfaces').TEpsgCode} epsgCode
 * @return {Boolean}
 */
export const isEpsgCodeValid = (epsgCode) => {
  if (typeof epsgCode !== 'string' || epsgCode?.length === 0) {
    return false;
  }
  const EPSGRegexMatch = /EPSG:\d{1,6}/;
  const targetEPSGNotExist = !EPSGLookup?.[epsgCode]?.definition?.[1];

  if (isOffsetType(epsgCode)) {
    return true;
  }

  if (!epsgCode.match(EPSGRegexMatch)) {
    console.error(`String ${epsgCode} does not match the EPSG Index pattern!`);
    return false;
  }

  if (targetEPSGNotExist) {
    console.error('Provided EPSG code is not supported');
    return false;
  }

  return true;
};

/**
 * Validates the potential object to match location Object
 * @param {import('common/gis/gis.interfaces').ILocationObject} object
 * @return {boolean}
 */
export const isLocationObjectValid = (object) => {
  if (
    typeof object !== 'object' ||
    object === null ||
    Object.keys(object)?.length === 0
  ) {
    return false;
  }

  const isValidEpsgCode = isEpsgCodeValid(object?.epsgCode);
  const isValidCoordinate = areAllArgumentsNumeric(object?.x, object?.y);

  if (isValidEpsgCode && isValidCoordinate) {
    return true;
  } else {
    console.error('Object does not match location object pattern!');
    return false;
  }
};
