import { useState } from 'react';
import { HierarchyType } from '~src/enums/compare-estimates';
import { ITableProps, Table } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { ICampaignEstimate } from '~src/common/interfaces/campaigns.interfaces';

type THierarchyTable = {
  estimatesList: ICampaignEstimate[];
  watchEstimates: ICampaignEstimate[],
  append: UseFieldArrayAppend<{projectId: string}>,
  remove: UseFieldArrayRemove
}
const HierarchyTable = ({ estimatesList, watchEstimates, append, remove }: THierarchyTable) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOnChangeInput = (ev: React.ChangeEvent<HTMLSelectElement>, identifier: string) => {
    setSelectedPage(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [identifier]: ev.target.value,
    }));
  };

  const headerLabels = [
    { label: t(translations.estimate), identifier: HierarchyType.Name },
    { label: t(translations.well), identifier: HierarchyType.WellName },
    { label: t(translations.wellbore), identifier: HierarchyType.WellboreName },
    { label: t(translations.site), identifier: HierarchyType.SiteName },
    { label: t(translations.country), identifier: HierarchyType.CountryName },
  ];

  const headerRow = headerLabels.map(({ label, identifier }) => ({
    value: label,
    label: identifier,
    width: 150,
  }));

  const headerFilter = headerLabels.map((header) => {
    const { identifier } = header;
    const filterValue = filters[identifier] ?? '';
    return {
      key: identifier,
      value: filterValue,
      type: 'Input',
      placeholder: t(translations.search),
      onChange: (ev: React.ChangeEvent<HTMLSelectElement>) => handleOnChangeInput(ev, identifier),
    };
  });

  const startIndex = (selectedPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredData = estimatesList.filter((design) =>
    Object.keys(filters).every((key) => {
      const filterValue = filters[key];
      const designValue = design[key as keyof ICampaignEstimate];
  
      return (
        filterValue === '' ||
        (designValue != null && designValue.toString().toLowerCase().includes(filterValue.toLowerCase()))
      );
    })
  );


  const rows = [
    ...filteredData.map((estimate) => {
      const isChecked = watchEstimates.some(
        (item) => item.projectId === estimate.projectId,
      );
      const rowCells = [
        {
          type: 'CheckBox',
          checked: isChecked,
          onChange: () => {
            if (!isChecked) {
              append({ projectId: estimate.projectId });
            } else {
              remove(
                watchEstimates.findIndex(
                  (item) => item.projectId === estimate.projectId,
                ),
              );
            }
          },
        },
        ...Object.entries(
          pick(estimate, [
            HierarchyType.Name,
            HierarchyType.WellName,
            HierarchyType.WellboreName,
            HierarchyType.SiteName,
            HierarchyType.CountryName,
          ]),
        ).map(([key, value]) => ({
          key,
          value,
        })),
      ];
      return { cells: rowCells };
    }),
  ];

  const filteredRows = rows.slice(startIndex, endIndex);

  const table: ITableProps['table'] = {
    fixedWidth: 'auto',
    headers: [{ cells: [{}, ...headerRow] }, { cells: [{}, ...headerFilter] }],
    rows: filteredRows,
    footer: {
      pagination: {
        rowCount: rows.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt) =>
            setRowsPerPage(Number(evt.target.value)),
          options: [
            { label: `10 / ${t(translations.page)}`, value: 10 },
            { label: `20 / ${t(translations.page)}`, value: 20 },
            { label: `50 / ${t(translations.page)}`, value: 50 },
          ],
          value: rowsPerPage,
        },
        onSelectPage: setSelectedPage,
        small: true,
      },
    },
  };

  return <Table table={table} />;
};

export { HierarchyTable };
