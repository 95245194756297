import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import ExcelJS from 'exceljs';
import { itemFields } from '~src/enums/time-tracker';
import { round, toNum } from '@oliasoft-open-source/units';
import { isEmpty } from 'lodash';
import { toast } from '@oliasoft-open-source/react-ui-library';
import { buildHeaders } from '../operations-table-builder';
import { formatTimeToExcel } from './date/dateUtils';
import { findSectionItemNameById } from './find-section-item-name';

async function writeExcelFile(workBook, fileName) {
  const buffer = await workBook.xlsx.writeBuffer(fileName);

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const borderStyle = { style: 'thin', color: { argb: 'D9D9D9' } };

const colorHeaderRow = (row) =>
  row.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E2E2DC' },
    };
    cell.border = {
      top: borderStyle,
      left: borderStyle,
      bottom: borderStyle,
      right: borderStyle,
    };
  });

/**
 * Autofit columns by width: https://github.com/exceljs/exceljs/discussions/2535#discussioncomment-7075656
 *
 * @param worksheet {ExcelJS.Worksheet}
 * @param minimalWidth
 */
export const autoWidth = (worksheet, minimalWidth = 5) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0,
      );
    });
    column.width = maxColumnLength;
  });
};
const headerRowNumber = 4;
const firstOperationRowNumber = 5;

export const downloadTimeTrackerExcel = ({
  wellName,
  totalAFE,
  startTime,
  estimatedFinish,
  variance,
  itemsList,
  operations,
  activities,
  sections = [],
  lengthOfTimeTracker,
}) => {
  const workBook = new ExcelJS.Workbook();
  const workSheet = workBook.addWorksheet(
    i18n.t(translations.timeTracker_timeTracker),
  );

  const topHeaders = [
    i18n.t(translations.wellName),
    i18n.t(translations.timeTracker_totalAFE),
    i18n.t(translations.timeTracker_startTime),
    i18n.t(translations.timeTracker_estimatedFinish),
    i18n.t(translations.timeTracker_variance),
  ];
  const topValues = [
    wellName,
    totalAFE,
    formatTimeToExcel(startTime),
    formatTimeToExcel(estimatedFinish),
    variance,
  ];

  workSheet.addRow(topHeaders);
  colorHeaderRow(workSheet.lastRow);
  workSheet.addRow(topValues);
  workSheet.addRow();

  const rawHeaders = buildHeaders();
  workSheet.addRow();
  let i = 1;
  for (const headerCell of rawHeaders[0].cells) {
    const letter = String.fromCharCode(i + 64);
    workSheet.getCell(`${letter}4`).value = headerCell.value || '';
    if (headerCell.colSpan) {
      i += headerCell.colSpan;
      const colSpanletter = String.fromCharCode(i - 1 + 64);
      workSheet.mergeCells(`${letter}4:${colSpanletter}4`);
    } else {
      i += 1;
    }
  }

  const headers = rawHeaders[1].cells.map((cell) => cell.value);
  const isOperationLetter = String.fromCharCode(headers.length + 1 + 64);
  workSheet.getCell(`${isOperationLetter}${headerRowNumber}`).value =
    'Is Operation';
  colorHeaderRow(workSheet.lastRow);

  workSheet.addRow(headers);
  workSheet.getCell(`${isOperationLetter}${firstOperationRowNumber}`).value =
    '';
  colorHeaderRow(workSheet.lastRow);
  workSheet.lastRow.eachCell(
    (cell) => (cell.alignment = { horizontal: 'right' }),
  );

  const getCells = (row) => {
    const formattedRow = {
      ...row,
      variance: row.actual ? round(row.actual - row.afe, 2) : '',
      start: formatTimeToExcel(row.start),
      finish: formatTimeToExcel(row.finish),
      section:
        findSectionItemNameById(sections, row?.sectionId, 'sectionId') ?? '',
      operation:
        findSectionItemNameById(
          operations,
          row?.sectionsOperationId,
          'sectionsOperationId',
        ) ?? '',
      activity:
        findSectionItemNameById(
          activities,
          row?.sectionsOperationActivityId,
          'sectionsOperationActivityId',
        ) ?? '',
    };
    return Object.values(itemFields).map((item) => formattedRow[item] || '');
  };

  itemsList.forEach((operation) => {
    const operationRow = workSheet.addRow([...getCells(operation), true]);
    colorHeaderRow(operationRow);

    operation.activities.forEach((activity) => {
      workSheet.addRow([
        ...getCells({
          ...activity,
          sectionsOperationId: operation.sectionsOperationId,
          section: operation.section,
        }),
        false,
      ]);
    });
  });

  autoWidth(workSheet);

  const sectionOrderMap = sections.reduce((acc, section, index) => {
    acc[section.sectionId] = index;
    return acc;
  }, {});

  // Sort activities based on the sectionId order in the sections array
  const sortedActivities = [...activities].sort((a, b) => {
    const operationA = operations.find(
      (op) => op.sectionsOperationId === a.sectionsOperationId,
    );
    const operationB = operations.find(
      (op) => op.sectionsOperationId === b.sectionsOperationId,
    );
    return (
      sectionOrderMap[operationA.sectionId] -
      sectionOrderMap[operationB.sectionId]
    );
  });

  const tableRows = [];

  sortedActivities.forEach((activity) => {
    const operation = operations.find(
      (op) => op.sectionsOperationId === activity.sectionsOperationId,
    );
    const section = sections.find(
      (sec) => sec.sectionId === operation.sectionId,
    );
    tableRows.push([section.name, operation.name, activity.name]);
  });

  workSheet.addTable({
    name: 'HelperTable',
    ref: 'AA7',
    rows: tableRows,
    columns: [{ name: 'Section' }, { name: 'Operation' }, { name: 'Activity' }],
  });

  const isOperationColNum = headers.length + 1;

  const sectionColIdx = rawHeaders[0].cells.findIndex(
    (cell) => cell.columnName === itemFields.SECTION,
  );
  const sectionColNum = sectionColIdx ? sectionColIdx + 1 : sectionColIdx;

  const operationColIdx = rawHeaders[0].cells.findIndex(
    (cell) => cell.columnName === itemFields.OPERATION,
  );
  const operationColNum = operationColIdx
    ? operationColIdx + 1
    : operationColIdx;

  const activityColIdx = rawHeaders[0].cells.findIndex(
    (cell) => cell.columnName === itemFields.ACTIVITY,
  );

  const activityColNum = activityColIdx ? activityColIdx + 1 : operationColIdx;
  workSheet.eachRow((row, rowNumber) => {
    if (rowNumber > firstOperationRowNumber) {
      row.eachCell((cell, colNumber) => {
        if (colNumber === isOperationColNum) {
          cell.dataValidation = {
            type: 'list',
            allowBlank: false,
            formulae: ['"True,False"'],
          };
        }
        if (colNumber === sectionColNum) {
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [`=_xlfn.INDIRECT("HelperTable[Section]")`],
          };
        }
        if (colNumber === operationColNum) {
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [
              `=_xlfn.OFFSET($BA$${rowNumber}, 0, 0, 1, _xlfn.COUNTA($BA$${rowNumber}:$BZ$${rowNumber})-_xlfn.SUMPRODUCT(--_xlfn.ISERROR($BA$${rowNumber}:$BZ$${rowNumber})))`,
            ],
          };
        }
        if (colNumber === activityColNum) {
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [
              `=_xlfn.OFFSET($CA$${rowNumber}, 0, 0, 1, _xlfn.COUNTA($CA$${rowNumber}:$CZ$${rowNumber})-_xlfn.SUMPRODUCT(--_xlfn.ISERROR($CA$${rowNumber}:$CZ$${rowNumber})))`,
            ],
          };
        }
      });
    }
  });

  for (
    let i = firstOperationRowNumber + 1;
    i <= lengthOfTimeTracker + firstOperationRowNumber + 1;
    i += 1
  ) {
    workSheet.getCell(`BA${i}`).model = {
      address: `BA${i}`,
      formula: `_xlfn.TOROW(_xlfn.UNIQUE(_xlfn._xlws.FILTER(HelperTable[Operation], HelperTable[Section]=M${i})))`,
      ref: `BA${i}:BZ${i}`,
      shareType: 'array',
      type: 6,
    };
    workSheet.getCell(`CA${i}`).model = {
      address: `CA${i}`,
      formula: `=_xlfn.TOROW(_xlfn.IFERROR(_xlfn._xlws.FILTER(HelperTable[Activity], (HelperTable[Section]=M${i}) * (HelperTable[Operation]=N${i})), "None"))`,
      ref: `CA${i}:CZ${i}`,
      shareType: 'array',
      type: 6,
    };
    workSheet.addConditionalFormatting({
      ref: `N${i}`,
      rules: [
        {
          type: 'expression',
          formulae: [`=$CA$${i}="None"`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFC7CD' },
            },
          },
        },
      ],
    });
    if (workSheet.getCell(`O${i}`).value) {
      workSheet.getCell(`I${i}`).value = {
        formula: `IF(OR(C${i}="",F${i}=""),"",C${i}-F${i})`,
        result: 0,
      };
      workSheet.addConditionalFormatting({
        ref: `O${i}`,
        rules: [
          {
            type: 'expression',
            formulae: [`=_xlfn.COUNTIF($CA$${i}:$CZ$${i}, O${i})=0`],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'FFC7CD' },
              },
            },
          },
        ],
      });
      workSheet.addConditionalFormatting({
        ref: `$G$${i}:$H$${i}`,
        rules: [
          {
            type: 'expression',
            formulae: [`=$G$${i} + $H$${i} > $F$${i}`],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'FFC7CD' },
              },
            },
          },
        ],
      });
    }
  }
  // Hide helper colmuns
  for (let i = 27; i < 105; i += 1) {
    workSheet.getColumn(i).hidden = true;
  }

  writeExcelFile(workBook, wellName);
};

const mapWorksheetToOperations = (worksheet, projectId) => {
  const operations = [];
  const activities = [];

  const headerValues = worksheet.getRow(2).values;
  const startTime = headerValues[4];
  worksheet.getCell('A5').value = startTime;

  const headerFieldMap = {
    'Start time': itemFields.START,
    'Operation/Activity': itemFields.NAME,
    AFE: itemFields.AFE,
    Estimate: itemFields.ESTIMATE,
    P90: itemFields.P90,
    Actual: itemFields.ACTUAL,
    NPT: itemFields.NPT,
    WOW: itemFields.WOW,
    Variance: itemFields.VARIANCE,
    'Finish time': itemFields.FINISH,
    Planned: itemFields.PLANNED_DEPTH,
    'Actual Depth': itemFields.ACTUAL_DEPTH,
    Section: itemFields.SECTION,
    Operation: itemFields.OPERATION,
    Activity: itemFields.ACTIVITY,
    'Is Operation': 'isOperation',
  };
  const headerFields = worksheet.getRow(headerRowNumber).values.map((cell) => {
    return headerFieldMap[cell];
  });
  const formatExcelToApiDateTime = (time) => {
    const date = `${time.slice(6, 10)}-${time.slice(3, 5)}-${time.slice(
      0,
      2,
    )} ${time.slice(11, 13)}:${time.slice(14, 16)}:00`;
    return date;
  };

  const getCellValue = (value, key) => {
    switch (key) {
      case itemFields.AFE:
      case itemFields.ESTIMATE:
      case itemFields.P90:
      case itemFields.ACTUAL:
      case itemFields.NPT:
      case itemFields.WOW:
      case itemFields.VARIANCE:
      case itemFields.ACTUAL_DEPTH:
      case itemFields.PLANNED_DEPTH:
        return value === '' ? 0 : toNum(value);
      case itemFields.START:
      case itemFields.FINISH:
        return formatExcelToApiDateTime(value);
      default:
        return value;
    }
  };

  const rowToActivityObject = (row) =>
    row.values.reduce(
      (acc, cell, index) => {
        const key = headerFields[index];
        const value = getCellValue(cell, key);
        if (key !== undefined) {
          acc[key] = value;
        }
        return acc;
      },
      { projectId },
    );

  const isOperationIdx = headerFields.findIndex(
    (header) => header === 'isOperation',
  );
  const isOperationLetter = String.fromCharCode(isOperationIdx + 64);
  worksheet.eachRow((row, index) => {
    if (index > firstOperationRowNumber) {
      const operation = rowToActivityObject(row);
      if (
        row.getCell('A')?.fill?.pattern === 'solid' ||
        row.getCell(isOperationLetter)?.value?.result === true ||
        row.getCell(isOperationLetter)?.value === true
      ) {
        operations.push({ ...operation, timeTrackerItemId: index.toString() });
      } else if (!isEmpty(operations) && row.getCell('A')?.value) {
        const lastOperation = operations[operations.length - 1];
        const activity = {
          ...operation,
          parentId: lastOperation.timeTrackerItemId,
        };
        activities.push(activity);
        if (lastOperation.activities) {
          lastOperation.activities.push(activity);
        } else {
          lastOperation.activities = [activity];
        }
      }
    }
  });
  return operations;
};

export const uploadTimeTrackerExcel = async (
  dispatch,
  uploadOperations,
  projectId,
) => {
  const input = document.createElement('input');
  input.type = 'file';

  input.onchange = (fileEvent) => {
    const file = fileEvent.target.files[0];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async (readEvent) => {
      const data = readEvent.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.worksheets[0];
      const operations = mapWorksheetToOperations(worksheet, projectId);
      if (isEmpty(operations)) {
        toast({
          message: {
            type: 'Error',
            content: 'No operations',
          },
        });
      } else {
        dispatch(uploadOperations(operations));
      }
    };
  };
  input.click();
};
