import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { resultViewMode } from '~src/enums/compare-estimates';
import { simulationStatuses } from '~src/enums/simulations';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import { toast } from '@oliasoft-open-source/react-ui-library';

const compareEstimates = createSlice({
  name: 'compareEstimates',
  initialState: {
    showCompareModal: false,
    showCompareEstimatesModal: false,
    isAdding: false,
    isFetching: false,
    viewMode: resultViewMode.COMBINED,
    designs: [],
    list: [],
    estimates: [],
    estimatesList: [],
    countries: [],
  },
  reducers: {
    compareDataRequested: (compareEstimates) => {
      compareEstimates.isFetching = true;
    },
    compareDataFetched: (compareEstimates, action) => {
      compareEstimates.isFetching = false;
      compareEstimates.designs = action.payload.designs;
    },
    compareDataRequestFailed: (compareEstimates) => {
      compareEstimates.isFetching = false;
    },
    compareRequested: (compareEstimates) => {
      compareEstimates.isFetching = true;
    },
    compareFetched: (compareEstimates, action) => {
      compareEstimates.isFetching = false;
      compareEstimates.showCompareModal = false;
      compareEstimates.list = action.payload.compareList.map((compare) => {
        compare.active = compare.compareId === action.payload.compare.compareId;
        return compare;
      });
      compareEstimates.estimates = action.payload.estimates;
      const activeCompare = compareEstimates.list.find(
        (compare) => compare.active,
      );
      compareEstimates.estimates = compareEstimates.estimates.map(
        (estimate) => {
          if (estimate.compareId === activeCompare.compareId) {
            estimate.simulation = {};
            estimate.simulation.status = simulationStatuses.INITIALISING;
          }
          return estimate;
        },
      );
      compareEstimates.estimatesList = action.payload.estimatesList;
    },
    compareRequestFailed: (compareEstimates) => {
      compareEstimates.isFetching = false;
    },
    showCompareModalUpdated: (compareEstimates, action) => {
      compareEstimates.showCompareModal = action.payload;
    },
    showCompareEstimatesModalUpdated: (compareEstimates, action) => {
      compareEstimates.showCompareEstimatesModal = action.payload;
    },
    compareListRequested: (compareEstimates) => {
      compareEstimates.isFetching = true;
    },
    compareListFetched: (compareEstimates, action) => {
      compareEstimates.isFetching = false;
      compareEstimates.list = action.payload.compareList;
      compareEstimates.estimatesList = action.payload.estimatesList;
      compareEstimates.estimates = action.payload.estimates.map((e) => ({
        ...e,
        visible: true,
      }));
      compareEstimates.countries = action.payload.countries;
    },
    compareListRequestFailed: (compareEstimates) => {
      compareEstimates.isFetching = false;
    },
    compareSelected: (compareEstimates, action) => {
      const { list } = compareEstimates;
      compareEstimates.viewMode = resultViewMode.COMBINED;
      compareEstimates.list = list.map((compare) => {
        compare.active = compare.compareId === action.payload;
        return compare;
      });
    },
    estimateRemoved: (compareEstimates, action) => {
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.id,
      );
      delete estimates[index];
      compareEstimates.estimates = estimates.filter((i) => i);
    },
    addSimulationStatus: (compareEstimates, action) => {
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (estimate) => estimate.jobId === action.payload.metadata.jobId,
      );
      if (index !== -1) {
        compareEstimates.estimates[index].simulation = {};
        compareEstimates.estimates[index].simulation.status =
          action.payload.status;
        compareEstimates.estimates[index].simulation.message =
          action.payload.message;
      }
    },
    simulationRequested: (compareEstimates, action) => {
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.projectId,
      );
      if (index !== -1) {
        compareEstimates.estimates[index].isFetching = true;
      }
    },
    simulationFetched: (compareEstimates, action) => {
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.projectId,
      );
      if (index !== -1) {
        compareEstimates.estimates[index].simulationResult = action.payload;
        compareEstimates.estimates[index].isFetching = false;
        compareEstimates.estimates[index].simulation.fetched = true;
      }
    },
    simulationRequestFailed: (compareEstimates, action) => {
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.projectId,
      );
      if (index !== -1) {
        compareEstimates.estimates[index].isFetching = false;
      }
    },
    toggleVisible: (compareEstimates, action) => {
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload,
      );
      if (index !== -1) {
        compareEstimates.estimates[index].visible =
          !compareEstimates.estimates[index].visible;
      }
    },
    viewModeUpdated: (compareEstimates, action) => {
      compareEstimates.viewMode = action.payload;
    },
    compareRemoved: (compareEstimates, action) => {
      const { list } = compareEstimates;
      const index = list.findIndex(
        (compare) => compare.compareId === action.payload.id,
      );
      delete list[index];
      compareEstimates.list = list.filter((i) => i);
    },
    compareUpdateRequested: (compareEstimates) => {
      compareEstimates.isAdding = true;
    },
    compareUpdated: (compareEstimates, action) => {
      compareEstimates.isAdding = false;
      const { list } = compareEstimates;
      const index = list.findIndex(
        (compare) => compare.compareId === action.payload.compare.compareId,
      );
      if (index !== -1) {
        action.payload.compare.active = true;
        compareEstimates.list[index] = action.payload.compare;
      }
    },
    compareUpdateRequestFailed: (compareEstimates) => {
      compareEstimates.isAdding = false;
    },
    compareEstimatesFetched: (compareEstimates, action) => {
      compareEstimates.showCompareEstimatesModal = false;
      compareEstimates.isFetching = false;
      compareEstimates.list = action.payload.compareList.map((compare) => {
        compare.active = compare.compareId === action.payload.compare.compareId;
        return compare;
      });
      compareEstimates.estimates = action.payload.estimates;
    },
    compareAddRequested: (compareEstimates) => {
      compareEstimates.isAdding = true;
    },
    compareAdded: (compareEstimates, action) => {
      compareEstimates.isAdding = false;
      compareEstimates.list.forEach((estimate) => (estimate.active = false));
      compareEstimates.list.push({ ...action.payload, active: true });
    },
    compareAddRequestFailed: (compareEstimates) => {
      compareEstimates.isAdding = false;
    },
    compareItemRemoved: (compareEstimates, action) => {
      const { compareId, projectId } = action.payload;
      const { estimates } = compareEstimates;
      const index = estimates.findIndex(
        (item) => item.compareId === compareId && item.projectId === projectId,
      );
      delete estimates[index];
      compareEstimates.estimates = estimates.filter((i) => i);
    },
    compareConceptDesignsAdded: (compareEstimates, action) => {
      compareEstimates.isAdding = false;
      const { list } = compareEstimates;
      const index = list.findIndex(
        (item) => item.compareId === action.payload.compareId,
      );
      if (index !== -1) {
        action.payload.active = true;
        compareEstimates.list[index] = action.payload;
      }
    },
    compareConceptDesignRemoved: (compareEstimates, action) => {
      const { compareId, datasetId } = action.payload;
      const { list } = compareEstimates;
      const index = list.findIndex((item) => item.compareId === compareId);
      const designIndex = list[index].designs.findIndex(
        (design) => design.datasetid === datasetId,
      );
      list[index].designs.splice(designIndex, 1);
      compareEstimates.list = list.filter((i) => i);
    },
  },
});

export const {
  compareDataRequested,
  compareDataFetched,
  compareDataRequestFailed,
  compareRequested,
  compareFetched,
  compareRequestFailed,
  showCompareModalUpdated,
  compareListRequested,
  compareListFetched,
  compareListRequestFailed,
  compareSelected,
  estimateRemoved,
  addSimulationStatus,
  simulationRequested,
  simulationFetched,
  simulationRequestFailed,
  toggleVisible,
  viewModeUpdated,
  compareRemoved,
  compareUpdated,
  compareUpdateRequested,
  compareUpdateRequestFailed,
  showCompareEstimatesModalUpdated,
  compareEstimatesFetched,
  compareAddRequested,
  compareAdded,
  compareAddRequestFailed,
  compareItemRemoved,
  compareConceptDesignsAdded,
  compareConceptDesignRemoved,
} = compareEstimates.actions;
export default compareEstimates.reducer;

export const initialEstimate = {
  name: i18n.t(translations.compare_newComparison),
  active: true,
};

/**
 * Get compare data
 *
 * @param companyId
 */
export const getCompareData = (companyId) =>
  apiCallBegan({
    url: `/api/compare-estimates/run-data/${companyId}`,
    method: 'GET',
    onStart: compareDataRequested.type,
    onSuccess: (response) => ({
      type: compareDataFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.unexpectedError),
        },
      });
      return {
        type: compareDataRequestFailed.type,
      };
    },
  });

/**
 * Run concept compare
 *
 * @param data
 */
export const runConceptCompare = (data) =>
  apiCallBegan({
    url: `/api/compare-estimates/concept`,
    method: 'POST',
    data,
    onStart: compareRequested.type,
    onSuccess: (response) => ({
      type: compareFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.compare_failedToRunConceptCompare),
        },
      });
      return {
        type: compareRequestFailed.type,
      };
    },
  });

/**
 * Run estimate compare
 *
 * @param data
 */
export const runEstimateCompare = (data) =>
  apiCallBegan({
    url: `/api/compare-estimates`,
    method: 'POST',
    data,
    onStart: compareRequested.type,
    onSuccess: (response) => ({
      type: compareEstimatesFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.compare_failedToRunEstimateCompare),
        },
      });
      return {
        type: compareRequestFailed.type,
      };
    },
  });

/**
 * Function to retrieve a list of compare estimates for a given company ID.
 *
 * @param {string} companyId - The ID of the company for which to retrieve the compare estimates.
 * @returns {Promise} - A promise that resolves with the compare estimates list data.
 */
export const getCompareList = (companyId) =>
  apiCallBegan({
    url: `/api/compare-estimates/${companyId}`,
    method: 'GET',
    onStart: compareListRequested.type,
    onSuccess: (response) => ({
      type: compareListFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.compare_failedToGetCompareList),
        },
      });
      return {
        type: compareListRequestFailed.type,
      };
    },
  });

/**
 * Remove estimate
 *
 * @param id
 */
export const removeEstimate = (id) =>
  apiCallBegan({
    url: `/api/projects/estimate/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: estimateRemoved.type,
      payload: { id },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.compare_failedToRemoveEstimate),
        },
      });
    },
  });

/**
 * Get simulation data
 *
 * @param projectId
 */
export const getSimulation = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'GET',
    onStart: () => ({ type: simulationRequested.type, payload: { projectId } }),
    onSuccess: (response) => ({
      type: simulationFetched.type,
      payload: { projectId, ...response },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.compare_failedToGetSimulationData),
        },
      });
      return {
        type: simulationRequestFailed.type,
        payload: { projectId },
      };
    },
  });

/**
 * Removes the compare estimate with the given ID.
 *
 * @param {number} id - The ID of the compare estimate to remove.
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const removeCompare = (id) =>
  apiCallBegan({
    url: `/api/compare-estimates/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: compareRemoved.type,
      payload: { id },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToRemove),
        },
      });
    },
  });

/**
 * Updates the compare estimate with the given data.
 *
 * @param {Object} data - The data to update the compare estimate with.
 */
export const updateCompare = (data) =>
  apiCallBegan({
    url: `/api/compare-estimates/${data.compareId}`,
    method: 'POST',
    data,
    onStart: compareUpdateRequested.type,
    onSuccess: compareUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToUpdate),
        },
      });
      return {
        type: compareUpdateRequestFailed.type,
      };
    },
  });

/**
 * Adds an intial empty estimate
 *
 * @param {string} companyId - id of company in the form of a uuid.
 * @param {boolean} isConcept - boolean to show if it is welldesign concept.
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const addInitialEstimate = (companyId, isConcept = false) =>
  apiCallBegan({
    url: `/api/compare-estimates/estimates`,
    method: 'POST',
    data: { ...initialEstimate, companyId, isConcept },
    onStart: compareAddRequested.type,
    onSuccess: compareAdded.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.compare_failedToAddInitialEstimate),
        },
      });
      return compareAddRequestFailed.type;
    },
  });

/**
 * Removes the compare estimate item with the given compareId and projectId.
 *
 * @param {string} compareId - The compare-ID of the compare estimate to remove.
 * @param {string} projectId - The project-ID of the compare estimate to remove.
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const removeCompareItem = (compareId, projectId) =>
  apiCallBegan({
    url: `/api/compare-estimates/remove-item`,
    method: 'DELETE',
    data: { compareId, projectId },
    onSuccess: compareItemRemoved.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToRemove),
        },
      });
    },
  });

/**
 * Adds designs for welldesign comparison
 *
 * @param {Object[]} data - .
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const addConceptDesigns = (data) =>
  apiCallBegan({
    url: `/api/compare-estimates/concept-designs`,
    method: 'POST',
    data,
    onStart: compareAddRequested.type,
    onSuccess: compareConceptDesignsAdded.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToAdd),
        },
      });
      return {
        type: compareAddRequestFailed.type,
      };
    },
  });

/**
 * Removes the design item with the given compareId and datasetID.
 *
 * @param {string} compareId - The compare-ID of the concept design to remove.
 * @param {number} datasetId - The dataset-ID of the concept design to remove.
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const removeConceptDesign = (compareId, datasetId) =>
  apiCallBegan({
    url: `/api/compare-estimates/remove-concept-design`,
    method: 'DELETE',
    data: { compareId, datasetId },
    onSuccess: compareConceptDesignRemoved.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToRemove),
        },
      });
    },
  });
