import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { EffectType } from '~src/enums/project-modifiers';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

export const initialRisk = {
  name: '',
  certainty: 100,
  effect: 100,
  effectType: EffectType.Increase,
  operations: [],
};

const slice = createSlice({
  name: 'risks',
  initialState: {
    isAdding: false,
    isFetching: false,
    list: [],
  },
  reducers: {
    addRiskRequested: (risks) => {
      risks.isAdding = true;
    },
    riskAdded: (risks, action) => {
      risks.isAdding = false;
      const existingIndex = risks.list.findIndex(
        (item) => item.riskId === action.payload.riskId,
      );

      if (existingIndex === -1) {
        const index = risks.list.push(action.payload) - 1;
        risks.list = risks.list.map((item, i) => {
          item.active = i === index;
          return item;
        });
      } else {
        risks.list[existingIndex] = {
          ...risks.list[existingIndex],
          ...action.payload,
        };
        risks.list[existingIndex].active = true;
      }
    },
    addRiskRequestFailed: (risks) => {
      risks.isAdding = false;
    },
    risksRequested: (risks) => {
      risks.isFetching = true;
    },
    risksFetched: (risks, action) => {
      risks.isFetching = false;
      risks.list = action.payload;
    },
    risksRequestFailed: (risks) => {
      risks.isFetching = false;
    },
    riskRemoved: (risks, action) => {
      const { list } = risks;
      const index = list.findIndex((risk) => risk.riskId === action.payload);
      delete list[index];
      risks.list = list.filter((i) => i);
    },
    riskSelected: (risks, action) => {
      const { list } = risks;
      risks.list = list.map((item, i) => {
        item.active = i === action.payload;
        return item;
      });
    },
    riskUnselected: (risks) => {
      risks.list = risks.list.map((item) => {
        item.active = false;
        return item;
      });
    },
    risksReorderRequested: (risks, action) => {
      risks.isFetching = true;
      risks.list = action.payload;
    },
    risksReordered: (risks) => {
      risks.isFetching = false;
    },
    risksCleanup: (risks) => {
      risks.list = [];
    },
  },
});

export const {
  addRiskRequested,
  riskAdded,
  addRiskRequestFailed,
  risksRequested,
  risksFetched,
  risksRequestFailed,
  riskRemoved,
  riskSelected,
  riskUnselected,
  risksReorderRequested,
  risksReordered,
  risksCleanup,
} = slice.actions;
export default slice.reducer;

/**
 * Add risk
 *
 * @param data
 */
export const addRisk = (data) =>
  apiCallBegan({
    url: '/api/risks',
    method: 'POST',
    data,
    onStart: addRiskRequested.type,
    onSuccess: (response) => ({
      type: riskAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.risk_failedToAddRisk),
        },
      });
      return { type: addRiskRequestFailed.type };
    },
  });

/**
 * Add initial risk
 */
export const addInitialRisk = (projectId) =>
  addRisk({
    ...initialRisk,
    name: 'New risk',
    projectId,
  });

/**
 * Get risks list
 */
export const getRisks = (projectId) =>
  apiCallBegan({
    url: `/api/risks?projectId=${projectId}`,
    method: 'GET',
    onStart: risksRequested.type,
    onSuccess: (response) => ({
      type: risksFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.risks_failedToFetchRisks),
        },
      });
      return { type: risksRequestFailed.type };
    },
  });

/**
 * Remove risk
 *
 * @param id
 * @param data
 */
export const removeRisk = (id, data = {}) =>
  apiCallBegan({
    url: `/api/risks/${id}`,
    method: 'DELETE',
    data,
    onSuccess: () => ({
      type: riskRemoved.type,
      payload: id,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.risk_failedToRemoveRisk),
        },
      });
    },
  });

/**
 * Change order of risks
 *
 * @param reorder
 * @param list
 * @return {*}
 */
export const reorderRisks = (reorder, list) => {
  const risks = list.map((item) => item);
  const { to, from } = reorder;
  risks.splice(to, 0, risks.splice(from, 1)[0]);
  const data = risks.map((risk, i) => ({
    riskId: risk.riskId,
    position: i,
  }));

  return apiCallBegan({
    url: '/api/risks/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: risksReorderRequested.type,
      payload: risks,
    }),
    onSuccess: risksReordered.type,
  });
};
