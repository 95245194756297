import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const campaigns = createSlice({
  name: 'campaigns',
  initialState: {
    isAdding: false,
    isFetching: false,
    isFetchingCampaignResults: false,
    isRecalculating: false,
    isGeneratingEstimates: false,
    campaignModalVisible: false,
    estimateModalVisible: false,
    welldesignModalVisible: false,
    learningCurveModalVisible: false,
    kpiModalVisible: false,
    list: [],
    estimates: [],
    designs: [],
    estimatesList: [],
    countries: [],
    campaignTotal: [],
  },
  reducers: {
    listRequested: (campaigns) => {
      campaigns.isFetching = true;
    },
    listFetched: (campaigns, action) => {
      campaigns.isFetching = false;
      campaigns.list = action.payload.campaigns;
      campaigns.estimatesList = action.payload.estimatesList;
      campaigns.estimates = action.payload.estimates.map((e) => ({
        ...e,
        visible: true,
      }));
      campaigns.countries = action.payload.countries;
      campaigns.campaignTotal = action.payload.campaignTotal;
    },
    listRequestFailed: (campaigns) => {
      campaigns.isFetching = false;
    },
    campaignAddRequested: (campaigns) => {
      campaigns.isAdding = true;
    },
    campaignAdded: (campaigns, action) => {
      campaigns.isAdding = false;
      const existingIndex = campaigns.list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );

      if (existingIndex === -1) {
        const index =
          campaigns.list.push({
            ...action.payload,
            estimates: [],
            kpisList: [],
          }) - 1;
        campaigns.list = campaigns.list.map((item, i) => {
          item.active = i === index;
          return item;
        });
      } else {
        campaigns.list[existingIndex] = {
          ...campaigns.list[existingIndex],
          ...action.payload,
        };
        campaigns.list[existingIndex].active = true;
      }
    },
    campaignAddRequestedFailed: (campaigns) => {
      campaigns.isAdding = false;
    },
    campaignSelected: (campaigns, action) => {
      const { list } = campaigns;
      const index = list.findIndex(
        (campaign) => campaign.campaignId === action.payload,
      );
      campaigns.list = list.map((item, i) => {
        item.active = i === index;
        return item;
      });
    },
    campaignRemoved: (campaigns, action) => {
      const { list } = campaigns;
      const index = list.findIndex(
        (campaign) => campaign.campaignId === action.payload,
      );
      delete list[index];
      campaigns.list = list.filter((i) => i);
    },
    campaignModalOpened: (campaigns, action) => {
      const { list } = campaigns;
      const index = list.findIndex(
        (campaign) => campaign.campaignId === action.payload,
      );
      campaigns.list = list.map((item, i) => {
        item.active = i === index;
        return item;
      });

      campaigns.campaignModalVisible = true;
    },
    campaignModalClosed: (campaigns) => {
      campaigns.campaignModalVisible = false;
    },
    estimateModalUpdated: (campaigns, action) => {
      campaigns.estimateModalVisible = action.payload;
    },
    estimatesAddRequested: (campaigns) => {
      campaigns.isAdding = true;
    },
    estimatesAdded: (campaigns, action) => {
      const existingIndex = campaigns.list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (existingIndex !== -1) {
        campaigns.list[existingIndex] = {
          ...campaigns.list[existingIndex],
          estimates: action.payload.estimates,
          campaignTotal: {
            campaignId: action.payload.campaignId,
          },
        };
      }
      campaigns.campaignTotal = action.payload.campaignTotal;
      campaigns.isAdding = false;
      campaigns.estimateModalVisible = false;
      campaigns.isRecalculating = true;
    },
    estimatesAddRequestedFailed: (campaigns) => {
      campaigns.isAdding = false;
    },
    estimateRemoved: (campaigns, action) => {
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === action.payload.campaignId,
      );
      if (campaignIndex !== -1) {
        const { estimates } = list[campaignIndex];
        const estimateIndex = estimates.findIndex(
          (estimate) => estimate.campaignsEstimatesId === action.payload.id,
        );
        if (estimateIndex !== -1) {
          delete estimates[estimateIndex];
          campaigns.list[campaignIndex].estimates = estimates.filter((i) => i);
        }
      }
    },
    learningCurveModalUpdated: (campaigns, action) => {
      campaigns.learningCurveModalVisible = action.payload;
    },
    learningCurveUpdateRequested: (campaigns, action) => {
      campaigns.isAdding = true;
      const { campaignId, estimates } = action.payload;
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );
      if (campaignIndex !== -1) {
        for (const estimate of estimates) {
          const estimateIndex = list[campaignIndex].estimates.findIndex(
            (item) =>
              item.campaignsEstimatesId === estimate.campaignsEstimatesId,
          );
          if (estimateIndex !== -1) {
            campaigns.list[campaignIndex].estimates[estimateIndex].adjustment =
              estimate.adjustment;
          }
        }
      }
    },
    learningCurveUpdated: (campaigns) => {
      campaigns.isAdding = false;
      campaigns.learningCurveModalVisible = false;
    },
    learningCurveUpdateRequestFailed: (campaigns) => {
      campaigns.isAdding = false;
    },
    learningCurveFlagUpdateRequested: (campaigns, action) => {
      const { campaignId } = action.payload;
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );
      if (campaignIndex !== -1) {
        campaigns.list[campaignIndex].learningCurve =
          !campaigns.list[campaignIndex].learningCurve;
      }
    },
    reorderEstimatesRequested: (campaigns, action) => {
      const { campaignId, list } = action.payload;
      const campaignIndex = campaigns.list.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );
      if (campaignIndex !== -1) {
        campaigns.list[campaignIndex].estimates = list;
      }
    },
    kpiModalUpdated: (campaigns, action) => {
      campaigns.kpiModalVisible = action.payload;
    },
    kpisAddRequested: (campaigns) => {
      campaigns.isAdding = true;
    },
    kpisAdded: (campaigns, action) => {
      const existingIndex = campaigns.list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (existingIndex !== -1) {
        campaigns.list[existingIndex].kpisList = action.payload.kpisList;
      }
      campaigns.isAdding = false;
      campaigns.kpiModalVisible = false;
    },
    kpisAddRequestFailed: (campaigns) => {
      campaigns.isAdding = false;
    },
    kpiRemoved: (campaigns, action) => {
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === action.payload.campaignId,
      );
      if (campaignIndex !== -1) {
        const { kpisList } = list[campaignIndex];
        const kpiIndex = kpisList.findIndex(
          (kpi) => kpi.id === action.payload.id,
        );
        if (kpiIndex !== -1) {
          delete kpisList[kpiIndex];
          campaigns.list[campaignIndex].kpisList = kpisList.filter((i) => i);
        }
      }
    },
    welldesignModalUpdated: (campaigns, action) => {
      campaigns.welldesignModalVisible = action.payload;
    },
    campaignConceptDesignsAdded: (campaigns, action) => {
      campaigns.isAdding = false;
      const { list } = campaigns;
      const index = list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (index !== -1) {
        action.payload.active = true;
        campaigns.list[index] = action.payload;
      }
    },
    conceptDesignRemoved: (campaigns, action) => {
      const { campaignId, datasetId } = action.payload;
      const { list } = campaigns;
      const index = list.findIndex((item) => item.campaignId === campaignId);
      const designIndex = list[index].designs.findIndex(
        (design) => design.datasetid === datasetId,
      );
      list[index].designs.splice(designIndex, 1);
    },
    campaignRunConceptFetched: (campaigns, action) => {
      campaigns.estimates = action.payload.estimates;
      campaigns.list = campaigns.list.map((campaign) => {
        if (campaign.campaignId === action.payload.campaign.campaignId) {
          return {
            ...action.payload.campaign,
            active: true,
          };
        } else {
          return {
            ...campaign,
            active: false,
          };
        }
      });
      campaigns.campaignTotal = action.payload.campaignTotal;
      campaigns.isGeneratingEstimates = false;
    },
    simulationRequested: (campaigns, action) => {
      const { estimates } = campaigns;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.projectId,
      );
      if (index !== -1) {
        campaigns.estimates[index].isFetching = true;
      }
    },
    simulationFetched: (campaigns, action) => {
      const { projectId, ...simulation } = action.payload;
      const { estimates } = campaigns;
      campaigns.list.forEach((campaign) => {
        campaign.estimates.forEach((estimate) => {
          if (estimate.projectId === projectId) {
            estimate.simulation = simulation;
          }
        });
      });
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === projectId,
      );
      if (index !== -1) {
        campaigns.estimates[index].simulationResult = action.payload;
        campaigns.estimates[index].isFetching = false;
        campaigns.estimates[index].simulation.fetched = true;
      }
    },
    simulationRequestFailed: (campaigns, action) => {
      const { estimates } = campaigns;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.projectId,
      );
      if (index !== -1) {
        campaigns.estimates[index].isFetching = false;
      }
    },
    addSimulationStatus: (campaigns, action) => {
      const { estimates, campaignTotal } = campaigns;
      const index = estimates.findIndex((estimate) => {
        return estimate.jobId === action.payload.metadata.jobId;
      });
      const campaignTotalIndex = campaignTotal?.findIndex((item) => {
        return item.jobId === action.payload.metadata.jobId;
      });
      if (index !== -1) {
        campaigns.estimates[index].simulation = {};
        campaigns.estimates[index].simulation.status = action.payload.status;
        campaigns.estimates[index].simulation.message = action.payload.message;
        campaigns.estimates[index].simulation.progress =
          action.payload.progress;
      }
      if (campaignTotalIndex !== -1) {
        campaigns.campaignTotal[campaignTotalIndex].simulation = {};
        campaigns.campaignTotal[campaignTotalIndex].simulation.status =
          action.payload.status;
        campaigns.campaignTotal[campaignTotalIndex].simulation.message =
          action.payload.message;
        campaigns.campaignTotal[campaignTotalIndex].simulation.progress =
          action.payload.progress;
      }
    },

    campaignSimulationFetched: (campaigns, action) => {
      const { campaignId, ...simulation } = action.payload;
      const { campaignTotal } = campaigns;
      campaigns.list.forEach((campaign) => {
        if (campaign.campaignTotal?.campaignId === campaignId) {
          campaign.campaignTotal.simulation = simulation;
        }
      });
      const index = campaignTotal?.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );

      if (index !== -1) {
        campaigns.campaignTotal[index].simulationResult = action.payload;
        campaigns.campaignTotal[index].isFetching = false;
        campaigns.campaignTotal[index].simulation.fetched = true;
      }
    },
    campaignSimulationRequested: (campaigns, action) => {
      const { campaignTotal } = campaigns;
      const index = campaignTotal?.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (index !== -1) {
        campaigns.campaignTotal[index].isFetching = true;
      }
    },
    campaignSimulationRequestFailed: (campaigns, action) => {
      const { campaignTotal } = campaigns;
      const index = campaignTotal?.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (index !== -1) {
        campaigns.campaignTotal[index].isFetching = false;
      }
    },
    designsFetched: (campaigns, action) => {
      campaigns.designs = action.payload;
      campaigns.isFetching = false;
    },
    designsFetchFailed: (campaigns) => {
      campaigns.isFetching = false;
    },
    campaignResultsRequested: (campaigns) => {
      campaigns.isFetchingCampaignResults = true;
    },
    campaignResultsFetched: (campaigns, action) => {
      const updatedList = campaigns.list?.map((item) =>
        item.campaignId === action.payload.campaignId
          ? {
              ...item,
              estimates: item.estimates?.map((estimate) => ({
                ...estimate,
                simulation:
                  action.payload.estimates.find(
                    (payloadEstimate) =>
                      payloadEstimate.campaignsEstimatesId ===
                      estimate.campaignsEstimatesId,
                  )?.simulation || estimate.simulation,
              })),
              kpisList: action.payload.kpisList,
              campaignTotal: action.payload.campaignTotal,
            }
          : item,
      );
      campaigns.list = updatedList;
      campaigns.isFetchingCampaignResults = false;
    },
    runCampaignTotalRequested: (campaigns, action) => {
      const { campaignId } = action.payload;
      campaigns.campaignTotal = campaigns.campaignTotal.map((campaign) => {
        if (campaign.campaignId === campaignId) {
          return {
            ...campaign,
            simulation: {},
            simulationResult: {},
          };
        }
        return campaign;
      });
      campaigns.isRecalculating = true;
    },
    runCampaignTotalFetched: (campaigns, action) => {
      const updatedCampaignTotals = action.payload.campaignTotal;

      campaigns.campaignTotal = campaigns.campaignTotal.map((campaign) => {
        const updatedCampaign = updatedCampaignTotals.find(
          (updated) => updated.campaignId === campaign.campaignId,
        );
        return updatedCampaign ? { ...campaign, ...updatedCampaign } : campaign;
      });
    },
    campaignsCleanup: (campaigns) => {
      campaigns.list = [];
      campaigns.estimates = [];
      campaigns.designs = [];
      campaigns.estimatesList = [];
      campaigns.countries = [];
      campaigns.campaignTotal = [];
    },
    updateIsRecalculating: (campaigns, action) => {
      campaigns.isRecalculating = action.payload;
    },
    campaignRunConceptRequested: (camapigns) => {
      camapigns.isRecalculating = true;
      camapigns.isGeneratingEstimates = true;
    },
  },
});

export const {
  listRequested,
  listFetched,
  listRequestFailed,
  campaignAddRequested,
  campaignAdded,
  campaignSelected,
  campaignRemoved,
  campaignModalOpened,
  campaignModalClosed,
  estimateModalUpdated,
  estimatesAddRequested,
  estimatesAddRequestedFailed,
  estimatesAdded,
  estimateRemoved,
  learningCurveModalUpdated,
  learningCurveUpdateRequested,
  learningCurveUpdated,
  learningCurveUpdateRequestFailed,
  learningCurveFlagUpdateRequested,
  reorderEstimatesRequested,
  kpiModalUpdated,
  kpisAddRequested,
  kpisAdded,
  kpisAddRequestFailed,
  kpiRemoved,
  welldesignModalUpdated,
  campaignConceptDesignsAdded,
  conceptDesignRemoved,
  campaignRunConceptFetched,
  simulationRequested,
  simulationFetched,
  simulationRequestFailed,
  addSimulationStatus,
  campaignSimulationFetched,
  campaignSimulationRequested,
  campaignSimulationRequestFailed,
  designsFetched,
  campaignAddRequestedFailed,
  designsFetchFailed,
  campaignResultsFetched,
  campaignResultsRequested,
  runCampaignTotalRequested,
  runCampaignTotalFetched,
  campaignsCleanup,
  updateIsRecalculating,
  campaignRunConceptRequested,
} = campaigns.actions;
export default campaigns.reducer;

/**
 * Get campaigns data
 *
 * @param companyId
 */
export const getCampaigns = (companyId) =>
  apiCallBegan({
    url: `/api/campaigns/${companyId}`,
    method: 'GET',
    onStart: listRequested.type,
    onSuccess: (response) => ({
      type: listFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToGetCampaigns),
        },
      });
      return {
        type: listRequestFailed.type,
      };
    },
  });

/**
 * Add campaign
 *
 * @param companyId
 * @param data
 */
export const addCampaign = (companyId, data) =>
  apiCallBegan({
    url: `/api/campaigns/${companyId}`,
    method: 'POST',
    data,
    onStart: campaignAddRequested.type,
    onSuccess: (response) => ({
      type: campaignAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToAdd),
        },
      });
      return {
        type: campaignAddRequestedFailed.type,
      };
    },
  });

/**
 * Add initial campaign
 *
 * @param companyId
 * @param name
 */
export const addInitialCampaign = (companyId, name, isConcept = false) =>
  addCampaign(companyId, { name, isConcept });

/**
 * Remove campaign
 *
 * @param id
 */
export const removeCampaign = (id) =>
  apiCallBegan({
    url: `/api/campaigns/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: campaignRemoved.type,
      payload: id,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToDelete),
        },
      });
    },
  });

/**
 * Add estimates
 *
 * @param campaignId
 * @param data
 */
export const addEstimates = (campaignId, data) => {
  const estimates = data.estimates.map((estimate) => estimate.projectId);

  return apiCallBegan({
    url: `/api/campaign/${campaignId}/add-estimates`,
    method: 'POST',
    data: { estimates },
    onStart: estimatesAddRequested.type,
    onSuccess: (response) => ({
      type: estimatesAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToLoadEstimates),
        },
      });
      return {
        type: estimatesAddRequestedFailed.type,
      };
    },
  });
};

/**
 * Remove estimate
 *
 * @param id
 * @param campaignId
 */
export const removeEstimate = (id, campaignId) =>
  apiCallBegan({
    url: `/api/campaign/estimate/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: estimateRemoved.type,
      payload: { id, campaignId },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToDelete),
        },
      });
    },
  });

/**
 * Update learning curve
 *
 * @param campaignId
 * @param data
 */
export const updateLearningCurve = (campaignId, data) => {
  const estimates = data.map((estimate) => ({
    campaignsEstimatesId: estimate.campaignsEstimatesId,
    adjustment: estimate.adjustment,
  }));

  return apiCallBegan({
    url: `/api/campaigns/learning-curve`,
    method: 'POST',
    data: { estimates },
    onStart: () => ({
      type: learningCurveUpdateRequested.type,
      payload: { campaignId, estimates },
    }),
    onSuccess: learningCurveUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToUpdateLearningCurve),
        },
      });
      return {
        type: learningCurveUpdateRequestFailed.type,
      };
    },
  });
};

/**
 * Update learning curve flag
 *
 * @param campaignId
 */
export const updateLearningCurveFlag = (campaignId) =>
  apiCallBegan({
    url: `/api/campaigns/learning-curve/${campaignId}`,
    method: 'POST',
    data: {},
    onStart: () => ({
      type: learningCurveFlagUpdateRequested.type,
      payload: { campaignId },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.campaigns_failedToUpdateLearningCurveFlag,
          ),
        },
      });
    },
  });

/**
 * Reorders estimates for a given campaign.
 *
 * @param {object} reorder - An object specifying the indexes to reorder.
 * @param {number} reorder.to - The index to move the estimate to.
 * @param {number} reorder.from - The index of the estimate to be moved.
 * @param {object} campaign - An object representing the campaign.
 * @param {number} campaign.campaignId - The ID of the campaign.
 * @param {array} campaign.estimates - An array of estimates for the campaign.
 * @param {number} campaign.estimates[].campaignsEstimatesId - The ID of the estimate.
 *
 * @return {Promise} A Promise representing the asynchronous API call. Resolves with the result of the API call.
 */
export const reorderEstimates = (reorder, campaign) => {
  const { campaignId, estimates } = campaign;
  const list = estimates.map((item) => item);
  const { to, from } = reorder;
  list.splice(to, 0, list.splice(from, 1)[0]);
  const data = list.map((estimate, i) => ({
    campaignsEstimatesId: estimate.campaignsEstimatesId,
    position: i,
  }));

  return apiCallBegan({
    url: '/api/campaign/estimate/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: reorderEstimatesRequested.type,
      payload: { list, campaignId },
    }),
  });
};

/**
 * Adds KPIs to a campaign.
 *
 * @param {number} campaignId - The ID of the campaign.
 * @param {object} data - The data object containing the KPIs to be added.
 * @returns {Promise} A promise that resolves with the API response.
 */
export const addKpis = (campaignId, data) => {
  const kpis = data.kpis.map((kpi) => kpi);

  return apiCallBegan({
    url: `/api/campaign/${campaignId}/add-kpis`,
    method: 'POST',
    data: { kpis },
    onStart: kpisAddRequested.type,
    onSuccess: (response) => ({
      type: kpisAdded.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToAdd),
        },
      });
      return {
        type: kpisAddRequestFailed.type,
      };
    },
  });
};

export const deleteKpi = (id, campaignId) =>
  apiCallBegan({
    url: `/api/campaign/kpi/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: kpiRemoved.type,
      payload: { id, campaignId },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToDeleteKpi),
        },
      });
    },
  });

/**
 * Adds designs for welldesign campaigns
 *
 * @param {Object} data - .
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const addConceptDesigns = (data) =>
  apiCallBegan({
    url: 'api/campaign/concept-designs',
    method: 'POST',
    data,
    onStart: campaignAddRequested.type,
    onSuccess: campaignConceptDesignsAdded.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToAddConceptDesigns),
        },
      });
      return {
        type: campaignAddRequestedFailed.type,
      };
    },
  });

/**
 * Removes the design item with the given campaignId and datasetID.
 *
 * @param {string} campaignId - The compare-ID of the concept design to remove.
 * @param {number} datasetId - The dataset-ID of the concept design to remove.
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const removeConceptDesign = (campaignId, datasetId) =>
  apiCallBegan({
    url: `/api/campaign/remove-concept-design`,
    method: 'DELETE',
    data: { campaignId, datasetId },
    onSuccess: conceptDesignRemoved.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToDeleteConceptDesign),
        },
      });
    },
  });

export const runConceptCampaign = (data) =>
  apiCallBegan({
    url: 'api/campaign/concept',
    method: 'POST',
    data,
    onStart: campaignRunConceptRequested.type,
    onSuccess: (response) => ({
      type: campaignRunConceptFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToRunConceptCampaign),
        },
      });
      return {
        type: campaignAddRequestedFailed.type,
      };
    },
  });

/**
 * Get simulation data
 *
 * @param projectId
 */
export const getSimulation = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'GET',
    onStart: () => ({ type: simulationRequested.type, payload: { projectId } }),
    onSuccess: (response) => ({
      type: simulationFetched.type,
      payload: { projectId, ...response },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_errorGettingSimulationData),
        },
      });
      return {
        type: simulationRequestFailed.type,
        payload: { projectId },
      };
    },
  });

/**
 * Get campaign simulation data
 *
 * @param campaignId
 */
export const getCampaignSimulation = (campaignId) =>
  apiCallBegan({
    url: `/api/simulations/campaign/${campaignId}`,
    method: 'GET',
    onStart: () => ({
      type: campaignSimulationRequested.type,
      payload: { campaignId },
    }),
    onSuccess: (response) => ({
      type: campaignSimulationFetched.type,
      payload: { campaignId, ...response },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(
            translations.campaigns_errorGettingCampaignSimulationData,
          ),
        },
      });
      return {
        type: campaignSimulationRequestFailed.type,
        payload: { campaignId },
      };
    },
  });

/**
 * Get welldesign data
 *
 * @param companyId
 */
export const getDesigns = (companyId) =>
  apiCallBegan({
    url: `/api/campaigns/designs/${companyId}`,
    method: 'GET',
    onStart: listRequested.type,
    onSuccess: designsFetched.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToGetDesigns),
        },
      });
      return {
        type: designsFetchFailed.type,
      };
    },
  });

export const getCampaignResults = (campaignId) =>
  apiCallBegan({
    url: `api/campaigns/campaign-results/${campaignId}`,
    method: 'GET',
    onStart: campaignResultsRequested.type,
    onSuccess: campaignResultsFetched.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.campaigns_failedToGetCampaignResults),
        },
      });
      return {
        type: campaignAddRequestedFailed.type,
      };
    },
  });

/**
 * Run campaign simulation
 *
 * @param companyId
 */
export const runCampaignTotalSimulation = (campaignId) =>
  apiCallBegan({
    url: `api/campaigns/${campaignId}/run-campaign-total`,
    method: 'GET',
    onStart: () => ({
      type: runCampaignTotalRequested.type,
      payload: { campaignId },
    }),
    onSuccess: (response) => ({
      type: runCampaignTotalFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          heading: i18n.t(
            translations.campaigns_failedToRunCampaignTotalSimulation,
          ),
        },
      });
      return {
        type: campaignAddRequestedFailed.type,
      };
    },
  });
