import { createSlice } from '@reduxjs/toolkit';
import { ability } from '@oliasoft/authorization-ui';
import { apiCallBegan } from '~api/api';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const authorization = createSlice({
  name: 'authorization',
  initialState: {
    isFetching: false,
    withWelldesignLicense: false,
    subjectId: '',
  },
  reducers: {
    authRequested: (authorization) => {
      authorization.isFetching = true;
    },
    authFetched: (authorization, action) => {
      authorization.isFetching = false;
      authorization.withWelldesignLicense =
        action.payload.withWelldesignLicense;
      authorization.subjectId = action.payload.subjectId;
    },
    authRequestFailed: (authorization) => {
      authorization.isFetching = false;
    },
  },
});

export const { authRequested, authFetched, authRequestFailed } =
  authorization.actions;
export default authorization.reducer;

export const getAbility = (companyId) =>
  apiCallBegan({
    url: `/api/auth/${companyId}`,
    method: 'GET',
    onStart: authRequested.type,
    onSuccess: (response) => {
      ability.update(response.ability);

      return {
        type: authFetched.type,
        payload: response,
      };
    },
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.failedToGetAuthorize),
        },
      });
      return {
        type: authRequestFailed.type,
      };
    },
  });
