import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Page,
  List,
  Spacer,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { routes } from '~routes/routes';
import { generatePath, navigateToPath } from '~store/navigation/navigation';
import { getCompanies } from '~store/entities/companies/companies';
import { TopBar } from '~views/navigation/top-bar/top-bar';
import translations from '~src/internationalisation/translation-map.json';
import styles from './companies.module.less';

const Companies = ({ getCompanies, isFetching, companiesList }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <TopBar />
      <Page left={0}>
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <div className={styles.container}>
          <span>
            {t(translations.pleaseSelectWhichOrganizationYouRepresent)}
          </span>
          <Spacer />
          <List
            list={companiesList}
            bordered
            noHeader
            allWhite
            testId="organization-list"
          />
        </div>
      </Page>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    companies: { list, isFetching },
  } = entities;

  const companiesList = {
    items:
      list?.map(({ id, name }) => {
        const url = generatePath(routes.hierarchy.pattern.path, {
          company: id,
        });

        return {
          id,
          name,
          url,
          onClick: () => navigateToPath(url),
        };
      }) || [],
  };

  return { list, isFetching, companiesList };
};

const mapDispatchToProps = { getCompanies };

const Container = connect(mapStateToProps, mapDispatchToProps)(Companies);

export { Container as Companies };
